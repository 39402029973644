const SkillsgapBase = () => import('@/components/Skillsgap/SkillsgapBase');
const TestBase = () => import('@/components/SPTests/TestBase');
const SkillsgapTestSelection = () => import('@/components/Skillsgap/SkillsgapTestSelection');
const SkillsgapResults = () => import('@/components/Skillsgap/SkillsgapResults');
const SkillsgapOnboard = () => import('@/components/Skillsgap/SkillsgapOnboard');
const SkillsgapTestBuilder = () => import('@/components/Skillsgap/SkillsgapTestBuilder');
const SPTest = () => import('@/components/SPTests/SPTest');
const SPTestResults = () => import('@/components/SPTests/SPTestResults');

let defaultLayout = 'SkillsgapLayout';

export default [
  {
    path: '/skillsgap',
    name: 'skillsgapBase',
    component: SkillsgapBase,
    redirect: 'skillsgap/personal/create',
    meta: {
      layout: defaultLayout
    },
    children: [
      {
        path: ':userType/create',
        name: 'skillsgapTestSelection',
        component: SkillsgapTestSelection,
        meta: {
          layout: defaultLayout
        },
      },
      {
        path: 'results/:resultsId',
        name: 'skillsgapResults',
        component: SkillsgapResults,
        meta: {
          layout: defaultLayout
        },
      },
      {
        path: 'test-builder',
        name: 'skillsgapTestBuilder',
        component: SkillsgapTestBuilder,
        meta: {
          layout: defaultLayout
        },
      },
      {
        path: ':userType/:testGroupId',
        name: 'skillsgap',
        component: SkillsgapOnboard,
        meta: {
          layout: defaultLayout
        },
      },
      {
        path: 'tests/:groupTestId',
        name: 'testBase',
        component: TestBase,
        meta: {
          layout: defaultLayout
        },
        children: [
          {
            path: 'outcome/:groupResultId',
            name: 'tests',
            component: SPTest,
            meta: {
              layout: defaultLayout
            },
          },
          {
            path: 'results/:groupResultUuid',
            name: 'results',
            component: SPTestResults,
            meta: {
              layout: defaultLayout
            },
          },
        ]
      },
    ]
  },
];
