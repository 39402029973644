<template>
  <div v-if="user && user.is_emulation_mode" id="emulation" class="">
    <div class="left" />
    <div class="right" />
    <div class="top" />
    <div class="bottom" />
    <div class="emulation-title text-center">
      <span class="emulation-title-text">Login as</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Emulation',
  created() {
    // required data: user
    if (!this.user || !this.$store.getters['header/getLibrary']) {
      this.$store.dispatch('header/loadHeaderData').then(
        (response) => {},
        (error) => {
          console.log(error);
        }
      );
    }
  }
};
</script>
