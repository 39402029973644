import axios from 'axios';

const header = {
  namespaced: true,
  state: {
    // user: null,
    multigroupLabel: {},
    library: null,
    classes: null,
    documents: null,
    loaded: null,
    showSubMenu: false,
  },
  mutations: {
    /**
     * Setter for header.multigroupLabel
     * @param {object} state
     * @param data
     */
    setMultigroupLabel(state, data) {
      state.multigroupLabel = data;
    },
    /**
     * Setter for header.library
     * @param {object} state
     * @param data
     */
    setLibrary(state, data) {
      state.library = data;
    },
    /**
     * Setter for class list
     * @param {*} state
     * @param {*} data
     */
    setClasses(state, data) {
      state.classes = data;
    },
    /**
     * Setter for documents list
     * @param {*} state
     * @param {*} data
     */
    setDocuments(state, data) {
      state.documents = data;
    },
    /**
     * Setter for documents list
     * @param {object} state
     * @param {Promise} promise
     */
    setLoaded(state, promise) {
      state.loaded = promise;
    },
    setSubmenu(state, data) {
      state.showSubMenu = data;
    }
  },
  actions: {
    /**
     * Load the header data from the api and save to header store jhamm 4/18/18
     * @param {obj}
     * @returns Promise
     */
    loadHeaderData({ state, commit, dispatch }) {
      if (state.loaded) {
        return state.loaded;
      }
      let promise = new Promise((resolve, reject) => {
        axios.get(process.env.SPENV.APP_URL + '/api/v2/header', {withCredentials: true}).then(
          (response) => {
            const userResponse = response.data.user;
            // Update autoplay property to boolean data format jhamm 5/18/18
            if (userResponse.is_logged_in) {
              userResponse.settings.autoplay = parseInt(userResponse.settings.autoplay) !== 0;
              if (userResponse.organization) {
                let mgDisplay = {
                  singular: 'group',
                  plural: 'groups'
                };
                if (userResponse.organization.type === 'academic') {
                  mgDisplay = {
                    singular: 'class',
                    plural: 'classes'
                  };
                }
                commit('setMultigroupLabel', mgDisplay);
              }
            }
            commit('user/setUser', userResponse, { root: true });
            commit('setLibrary', response.data.library);
            commit('setClasses', response.data.classes);
            commit('setDocuments', response.data.documents);
            dispatch('showSubmenuCheck');
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
      commit('setLoaded', promise);
      return state.loaded;
    },
    showSubmenuCheck({ state, commit, contex, rootState, rootGetters }) {
      let userStore = rootState.user.user;
      if (
        !rootGetters['user/isLoggedin'] ||
        !rootGetters['user/isEmployee'] ||
        (userStore.organization && userStore.organization.role === 'member')
      ) {
        commit('setSubmenu', true);
        document.body.classList.add('sub-nav-padding');
      } else {
        commit('setSubmenu', false);
        document.body.classList.remove('sub-nav-padding');
      }
    }
  },
  getters: {
    getMultigroupLabel(state) {
      return state.multigroupLabel;
    },
    getLibrary(state) {
      return state.library;
    },
    getClasses(state) {
      return state.classes;
    },
    getDocuments(state) {
      return state.documents;
    },
    getSubMenuStatus(state) {
      return state.showSubMenu;
    },
  }
};

export default header;
