const admin = {
  namespaced: true,
  state: {
    loaded: null,
  },
  mutations: {
    /**
     * Setter for documents list
     * @param {object} state
     * @param {Promise} promise
     */
    setLoaded(state, promise) {
      state.loaded = promise;
    },
  },
};

export default admin;
