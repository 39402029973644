<template>
  <div>
    <div id="bg">
      <b-img :src="SPENV.S3_URL + '/images/backgrounds/home-hero-scene-v4.jpg'" />
    </div>
    <FixedAlert ref="myalert" v-model="show_alert" variant="danger">
      <div class="text-center">
        {{ alert_message | striphtml }}
      </div>
    </FixedAlert>
    <div class="container pt-4">
      <div class="row login-row">
        <div class="col-sm-12 col-md-6 col-lg-5 order-2 mx-auto mt-3">
          <div :class="this.$route.query.auth !== 'pltw' ? 'card' : null">
            <div :class="this.$route.query.auth !== 'pltw' ? 'card-body' : null">
              <div v-show="this.$route.query.auth !== 'pltw'" class="text-center mb-3">
                <a href="https://www.solidprofessor.com">
                  <b-img-lazy :src="SPENV.S3_URL + '/images/logos/sp-logo.png'" style="height:3.8em; width: auto" alt="SolidProfessor" />
                </a>
              </div>
              <div class="col">
                <div class="login-tab-switch row mb-3" />
              </div>
              <router-view @login-alert="showAlert" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FixedAlert from '@/components/FixedAlert';

export default {
  name: 'AccountLayout',
  components: {FixedAlert},
  data() {
    return {
      title: '',
      body: '',
      show_alert: false,
      alert_message: '',
    };
  },
  watch: {
    '$route.path': function() {
      this.setWelcomeText();
    },
  },
  created() {
    this.setWelcomeText();
  },
  methods: {
    setWelcomeText() {
      this.show_welcome_text = this.$route.name === 'Login' || this.$route.name === 'Signup';
    },
    emitAlert(alert) {
      this.$emit('alert', alert);
    },
    showAlert(eventMessage) {
      this.alert_message = eventMessage;
      this.show_alert = true;
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/login-forms.scss";
</style>
