import axios from 'axios';

const users = {
  namespaced: true,
  state: {
    users: null,
  },
  mutations: {
    setUsers(state, data) {
      state.users = data;
    },
  },
  actions: {
    /**
     * Set the user form data to the store
     * @param {*} param0
     * @param data
     */
    setUserData({state, commit}, data) {
      commit('setUsers', data);
    },
    validateEmail({state, commit}, email) {
      return new Promise((resolve, reject) => {
        axios({
          url: process.env.SPENV.APP_URL + '/api/v2/user/email',
          method: 'POST',
          data: {email: email},
          withCredentials: true,
        }).then(
          (response) => {
            resolve();
          },
          (error) => {
            console.log(error);
            reject(error);
          },
        );
      });
    },
    /**
     * Makes the api call POST/PATCH depending on the 'type' passed within the data
     * Some fields may be optional
     * Expects json:
     * {
        "multigroupID": "3376",
        "customers": [
          {
            "name": "jorge",
            "lastName": "Cervantez",
            "phone": "444-444-4444",
            "email": "pedro+spufi222@solidprofessor.com",
            "username": "jorgeeee",
            "password1": "test",
            "password2": "test"
          }
        ]
      }
     * @param state
     * @param commit
     * @param state
     * @param commit
     * @param {*} data
     */
    processUserData({state, commit}, data) {
      return new Promise((resolve, reject) => {
        let type = data['type'] === 'PATCH' ? 'patch' : 'post';
        axios({
          url: process.env.SPENV.APP_URL + '/api/v2/organization/' + data['organizationID'] + '/users',
          method: type,
          data: data,
          withCredentials: true,
        }).then(
          (response) => {
            if (response.status === 201) {
              resolve();
            }
          })
          .catch(
            (error) => {
              console.log(error);
              reject(error);
            });
      });
    },
  },
  getters: {
    getUsers: function(state) {
      return state.users;
    },
  },
};

export default users;
