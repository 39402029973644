import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'intersection-observer'; // Optional
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import App from './App.vue';
import router from './router';
import store from './store';
import BootstrapVue from 'bootstrap-vue';
import VueScrollTo from 'vue-scrollto';
import lineClamp from 'vue-line-clamp';
import Loading from 'vue-loading-overlay';
import VeeValidate from 'vee-validate';
import VueMeta from 'vue-meta';
import VueCookies from 'vue-cookies';
import UserPlugin from '@/utils/userPlugin';
import fontawesome from '@/utils/fontawesome'; // Load icons
import {
  FontAwesomeIcon,
  FontAwesomeLayers
} from '@fortawesome/vue-fontawesome';
import '@/filters';
import analytics from '@/../../shared/js/analytics.js';
import 'vue-loading-overlay/dist/vue-loading.css';
import '@/assets/scss/_custom.scss';

Vue.use(UserPlugin);
Vue.use(BootstrapVue);
Vue.use(Loading, {
  color: '#64A33D',
  loader: 'dots'
});
Vue.use(VueScrollTo);
Vue.use(VueCookies);
Vue.use(lineClamp, {});

// https://github.com/bootstrap-vue/bootstrap-vue/issues/1270
// https://github.com/bootstrap-vue/bootstrap-vue/blob/master/docs/markdown/reference/validation/README.md#veevalidate
Vue.use(VeeValidate, { fieldsBagName: 'veeFields' });

Vue.use(fontawesome);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});
analytics.start(true);

Vue.config.productionTip = process.env.NODE_ENV !== 'production';
Vue.config.devtools = process.env.NODE_ENV !== 'production';
Vue.config.performance = process.env.NODE_ENV !== 'production';

if (process.env.NODE_ENV === 'production' || process.env.SPENV.FORCE_DEBUG) {
  Sentry.init({
    dsn: process.env.SPENV.SENTRY_IO_CLIENT_APP,
    environment: process.env.NODE_ENV || 'development',
    release: process.env.SPENV.RELEASE_VERSION,
    integrations: [
      new Integrations.BrowserTracing(),
      new VueIntegration({ Vue,
        attachProps: true,
        logErrors: true,
        tracing: true,
        tracingOptions: {
          trackComponents: [
            'Signup',
            'SelectPlan',
            'AccountInfo',
            'Payment',
            'StudentStore',
            'SelectSchool',
            'StudentAccount',
            'StudentPayment',
            'StudentConfirmation',
          ],
          timeout: 4000,
        },
      }),
    ],
    tracesSampleRate: .25,
  });
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

