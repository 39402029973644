import axios from 'axios';
import Vue from 'vue';

const sptests = {
  namespaced: true,
  state: {
    answers: null,
    SPENV: process.env.SPENV,
    currentQuestion: 0,
    recommendedLessons: null,
    sections: null,
    selectedSummary: null,
    selectedTestId: null,
    test: null,
    testBank: null,
    testSummary: null,
    testSummaryPerUser: null
  },
  mutations: {
    setAnswers(state, data) {
      state.answers = data;
    },
    setCurrentQuestion(state, data) {
      state.currentQuestion = data;
    },
    setRecommendedLessons(state, data) {
      state.recommendedLessons = data;
    },
    setSections(state, data) {
      state.sections = data;
    },
    setSelectedSummary(state, data) {
      state.selectedSummary = data;
    },
    setSelectedTestId(state, data) {
      state.selectedTestId = data;
    },
    setTestAnswer(state, data) {
      Vue.set(state.test.questions[data.currentQuestion], 'submittedAnswer', data.answer);
    },
    setTest(state, data) {
      state.test = data;
    },
    setTestBank(state, data) {
      state.testBank = data;
    },
    setTestSummary(state, data) {
      state.testSummary = data;
    },
    setTestSummaryPerUser(state, data) {
      state.testSummaryPerUser = data;
    }
  },
  actions: {
    createSummary({commit, state}, data) {
      return new Promise((resolve, reject) => {
        axios.post(state.SPENV.LARAVEL_API + 'sp-tests/summary/user', data,{withCredentials: true}).then(
          (response) => {
            resolve(response);
          })
          .catch(
            (error) => {
              reject(error);
            });
      });
    },
    /**
     * Call to the API to get Answers for given test
     * @param commit
     * @param state
     * @param data
     * @returns {Promise<unknown>}
     *
     */
    getAnswers({commit, state}, data) {
      return new Promise((resolve, reject) => {
        axios.get(state.SPENV.LARAVEL_API + 'sp-tests/' + data.groupTestUuid + '/summary/' + data.resultTestUuid, {withCredentials: true}).then(
          (response) => {
            commit('setAnswers', response.data);
            resolve();
          })
          .catch(
            (error) => {
              reject(error);
            });
      });
    },
    /**
     * Retrieve N base tests from API
     * @param commit
     * @param state
     * @param params -- Object of tests you want to get, i.e. {param0: 0, param1: 1, param2: 2}
     * @returns {Promise<unknown>}
     */
    getBaseTests({commit, state}, params) {
      return new Promise((resolve, reject) => {
        let url = state.SPENV.LARAVEL_API + 'sp-tests/base-tests';
        axios.get(url, {params:params, withCredentials: true}).then(
          (response) => {
            commit('setTestBank', response.data);
            resolve();
          })
          .catch(
            (error) => {
              reject(error);
            });
      });
    },
    /**
     * Call to the API to get a Single Result Summary
     * Set it on the store.
     * @param resultTestUuid -- actual uuid as a string
     */
    getIndividualSummary({commit, state}, resultTestUuid) {
      return new Promise((resolve, reject) => {
        axios.get(state.SPENV.LARAVEL_API + 'sp-tests/summary/' + resultTestUuid + '/individual', {withCredentials: true}).then(
          (response) => {
            commit('setSelectedSummary', response.data);
            resolve();
          })
          .catch(
            (error) => {
              reject(error);
            });
      });
    },
    /**
     * Call API to get 'Recommended Lessons' based on test id
     * @param commit
     * @param state
     * @param testId
     * @returns {Promise<unknown>}
     */
    getRecommendedLessons({commit, state}, testId) {
      return new Promise((resolve, reject) => {
        axios.get(state.SPENV.LARAVEL_API + 'sp-tests/' + testId + '/recommended', {withCredentials: true}).then(
          (response) => {
            commit('setRecommendedLessons', response.data);
            resolve();
          })
          .catch(
            (error) => {
              reject(error);
            });
      });
    },
    /**
     * Call store to get a list of Sections per test id
     * @param commit
     * @param state
     * @param testId
     * @returns {Promise<unknown>}
     */
    getSections({commit, state}, testId) {
      return new Promise((resolve, reject) => {
        axios.get(state.SPENV.LARAVEL_API + 'sp-tests/' + testId + '/sections', {withCredentials: true}).then(
          (response) => {
            commit('setSections', response.data);
            resolve();
          })
          .catch(
            (error) => {
              reject(error);
            });
      });
    },
    getSummary({commit, state}, summaryId) {
      return new Promise((resolve, reject) => {
        axios.get(state.SPENV.LARAVEL_API + 'sp-tests/summary/' + summaryId,{withCredentials: true}).then(
          (response) => {
            commit('setTestSummary', response.data);
            resolve(response);
          })
          .catch(
            (error) => {
              reject(error);
            });
      });
    },
    getAllUsersSummary({commit, state}, summaryId) {
      return new Promise((resolve, reject) => {
        axios.get(state.SPENV.LARAVEL_API + 'sp-tests/summary/' + summaryId + '/users',{withCredentials: true}).then(
          (response) => {
            commit('setTestSummaryPerUser', response.data);
            resolve(response);
          })
          .catch(
            (error) => {
              reject(error);
            });
      });
    },
    /**
     * Call API to get entire test object based on ID
     * @param commit
     * @param dispatch
     * @param state
     * @param testId
     * @returns {Promise<unknown>}
     */
    getTest({commit, state}, testId) {
      return new Promise((resolve, reject) => {
        axios.get(state.SPENV.LARAVEL_API + 'sp-tests/' + testId + '/questions', {withCredentials: true}).then(
          (response) => {
            commit('setTest', response.data);
            resolve();
          })
          .catch(
            (error) => {
              reject(error);
            });
      });
    },
    /**
     * Saves question to DB, updates state with result
     * NOTE: This will continue to work, and update the store even after the user
     * has nav'd off page
     * @param state
     * @param commit
     * @param dispatch
     * @param data
     * @returns {Promise<unknown>}
     */
    saveAnswer({state, commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post(state.SPENV.LARAVEL_API + 'sp-tests/' + data['group_test_id'] + '/answers', data,{withCredentials: true}).then(
          (response) => {
            if (response.status === 200 || response.status === 201) {
              commit('setTestAnswer', {currentQuestion: data.currentQuestion, answer: data.answer_id});
            }
            resolve();
          })
          .catch(
            (error) => {
              reject(error);
            });
      });
    },
    /**
     * Upserts Test Group to API
     * Data requires:
     * test_name, source_test_id, first_name, last_name,email, company_name,
     * user_title, phone_number, idcustomer
     * @param state
     * @param commit
     * @param data
     * @returns {Promise<unknown>}
     */
    saveGroup({state, commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post(state.SPENV.LARAVEL_API + 'sp-tests/group', data,{withCredentials: true}).then(
          (response) => {
            resolve(response);
          })
          .catch(
            (error) => {
              reject(error);
            });
      });
    },
    /**
     * Sends an email through the Laravel API
     */
    sendEmail({state, commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post(state.SPENV.LARAVEL_API + 'email/send', data,{withCredentials: true}).then(
          (response) => {
            resolve(response);
          })
          .catch(
            (error) => {
              reject(error);
            });
      });
    },
    /**
     * Verify with API if test is valid
     * @param state
     * @param data -- requires `testid` (skillsgap_group id) and
     * `outcomeid` (skillsgap_results_summary base64 encoded) to prevent tampering
     * @returns {Promise<unknown>}
     */
    verifyTest({state}, data) {
      return new Promise((resolve, reject) => {
        axios.post(state.SPENV.LARAVEL_API + 'sp-tests/' + data['groupTestId'] + '/outcome/' + data['groupResultId'] + '/verify', {withCredentials: true}).then(
          (response) => {
            resolve(response);
          })
          .catch(
            (error) => {
              reject(error);
            });
      });
    },
  },
  getters: {
    getAnswers(state) {
      return state.answers;
    },
    getCurrentQuestion(state) {
      return state.currentQuestion;
    },
    getRecommendedLessons(state) {
      return state.recommendedLessons;
    },
    getSections(state) {
      return state.sections;
    },
    getSelectedSummary(state) {
      return state.selectedSummary;
    },
    getSelectedTestId(state) {
      return state.selectedTestId;
    },
    getTest(state) {
      return state.test;
    },
    getTestBank(state) {
      return state.testBank;
    },
    getTestSummary(state) {
      return state.testSummary;
    },
    getTestSummaryPerUser(state) {
      return state.testSummaryPerUser;
    },
  },
};

export default sptests;
