/* eslint max-len: ["error", { "code": 128, "comments": 128 }] */

const zendesk = {
  /**
   * Load the Zendesk script on mount. Since it is async, once it returns, attempt to load the User info
   */
  created() {
    // Set Zendesk custom settings
    window.zESettings = {
      webWidget: {
        answerBot: {
          avatar: {
            url: process.env.SPENV.MARKETING_ICON,
            name: {
              '*': 'SolidProfessor',
            },
          },
          title: {
            'en-US': 'Support',
          },
        },
      },
    };

    this.addScript('https://static.zdassets.com/ekr/snippet.js?key=' + process.env.SPENV.ZENDESK_KEY, 'ze-snippet')
        .then(() => {
          setTimeout(() => {
            this.setZendeskUserOrSetTimeOut();
          }, 500 );
        })
        .catch(() => {
          // Failed to fetch script
        });
  },
  methods: {
    /**
     * Programmatically adds script to Vue. Can be adapted to load any script
     * @param {*} src -- URL of script
     * @param {*} id -- OPTIONAL: set the id attribute on the element being loaded
     * @return {Promise} script
     */
    addScript(src, id = null) {
      return new Promise(function(resolve, reject) {
        if (document.querySelector('script[src="' + src + '"]')) {
          resolve();
          return;
        }

        const el = document.createElement('script');

        el.type = 'text/javascript';
        el.async = true;
        if (id) {
          el.setAttribute('id', id);
        }
        el.src = src;

        el.addEventListener('load', resolve);
        el.addEventListener('error', reject);
        el.addEventListener('abort', reject);

        document.head.appendChild(el);
      });
    },
    /**
     * Formats and trims name so it can be used in objects
     * NOTE: this is necessary because Vue trim() doesn't modify the string in place
     * @return {string} firstname, lastname
     */
    formatName() {
      return [this.user.first_name, this.user.last_name].join(' ').trim();
    },
    /**
     * Since loading the User is done async, retry MAX times.
     * Will not try to load users that are not logged in, but checks this.user MAX times in the event it is slow loading
     *
     * @param {int} attempts -- counter for current "try"
     * @param {int} MAX -- Max number of tries
     */
    setZendeskUserOrSetTimeOut(attempts = 0, MAX = 10) {
      if (attempts < MAX) {
        attempts = attempts + 1;
        if (this.user && this.user.is_logged_in) {
          try {
            window.zE.identify({
              name: this.formatName(),
              email: this.user.email,
              organization: this.user.organization.name,
            });
          } catch (error) {
            setTimeout(() => {
              this.setZendeskUserOrSetTimeOut(attempts, 10);
            }, 500 * attempts);
          }
        } else {
          setTimeout(() => {
            this.setZendeskUserOrSetTimeOut(attempts, 10);
          }, 500 * attempts);
        }
      }
    },
    /**
     * Supress all Zendesk functions when called
     */
    suppressZendeskAll() {
      window.zESettings = {
        webWidget: {
          chat: {
            suppress: true,
          },
          contactForm: {
            suppress: true,
          },
          helpCenter: {
            suppress: true,
          },
          talk: {
            suppress: true,
          },
          answerBot: {
            suppress: true,
          },
        },
      };
    },
  },
};

export default zendesk;
