const bodyClass = {
  mounted: function() {
    // Add class to body if declared in data
    if (this.$data.bodyClass) {
      document.body.classList.add(this.$data.bodyClass);
    }
  },
  beforeDestroy: function() {
    // Remove class to body if declared in data
    if (this.$data.bodyClass) {
      document.body.classList.remove(this.$data.bodyClass);
    }
  }
};

export default bodyClass;
