import { library } from '@fortawesome/fontawesome-svg-core';
// Only import the fontawesome icons you want to use
// We don't have to load thousands of unused icons this way
import faAmbulance from '@fortawesome/fontawesome-free-solid/faAmbulance';
import faAngleDown from '@fortawesome/fontawesome-free-solid/faAngleDown';
import faAngleRight from '@fortawesome/fontawesome-free-solid/faAngleRight';
import faBars from '@fortawesome/fontawesome-free-solid/faBars';
import faBell from '@fortawesome/fontawesome-free-solid/faBell';
import faBook from '@fortawesome/fontawesome-free-solid/faBook';
import faBookmark from '@fortawesome/fontawesome-free-solid/faBookmark';
import faCalendarAlt from '@fortawesome/fontawesome-free-solid/faCalendarAlt';
import faCaretDown from '@fortawesome/fontawesome-free-solid/faCaretDown';
import faCaretUp from '@fortawesome/fontawesome-free-solid/faCaretUp';
import faChartBar from '@fortawesome/fontawesome-free-solid/faChartBar';
import faChartPie from '@fortawesome/fontawesome-free-solid/faChartPie';
import faCheck from '@fortawesome/fontawesome-free-solid/faCheck';
import faCheckCircle from '@fortawesome/fontawesome-free-solid/faCheckCircle';
import faCheckSquare from '@fortawesome/fontawesome-free-solid/faCheckSquare';
import faChevronRight from '@fortawesome/fontawesome-free-solid/faChevronRight';
import faCircle from '@fortawesome/fontawesome-free-solid/faCircle';
import faCircleReg from '@fortawesome/fontawesome-free-regular/faCircle';
import faClock from '@fortawesome/fontawesome-free-regular/faClock';
import faClockSolid from '@fortawesome/fontawesome-free-solid/faClock';
import faCloudDownloadAlt from '@fortawesome/fontawesome-free-solid/faCloudDownloadAlt';
import faCog from '@fortawesome/fontawesome-free-solid/faCog';
import faDotCircle from '@fortawesome/fontawesome-free-solid/faDotCircle';
import faEllipsisV from '@fortawesome/fontawesome-free-solid/faEllipsisV';
import faExclamationCircle from '@fortawesome/fontawesome-free-solid/faExclamationCircle';
import faExclamationTriangle from '@fortawesome/fontawesome-free-solid/faExclamationTriangle';
import faExpand from '@fortawesome/fontawesome-free-solid/faExpand';
import faExternalLinkAlt from '@fortawesome/fontawesome-free-solid/faExternalLinkAlt';
import faEye from '@fortawesome/fontawesome-free-solid/faEye';
import faEyeSlash from '@fortawesome/fontawesome-free-solid/faEyeSlash';
import faFileAlt from '@fortawesome/fontawesome-free-regular/faFileAlt';
import faFilter from '@fortawesome/fontawesome-free-solid/faFilter';
import faFolder from '@fortawesome/fontawesome-free-regular/faFolder';
import faFolderOpen from '@fortawesome/fontawesome-free-solid/faFolderOpen';
import faGoogle from '@fortawesome/fontawesome-free-brands/faGoogle';
import faGraduationCap from '@fortawesome/fontawesome-free-solid/faGraduationCap';
import faHandPointUp from '@fortawesome/fontawesome-free-solid/faHandPointUp';
import faHospitalAlt from '@fortawesome/fontawesome-free-solid/faHospitalAlt';
import faHtml5 from '@fortawesome/fontawesome-free-brands/faHtml5';
import faIdCard from '@fortawesome/fontawesome-free-regular/faIdCard';
import faLinkedinIn from '@fortawesome/fontawesome-free-brands/faLinkedinIn';
import faList from '@fortawesome/fontawesome-free-solid/faList';
import faListUl from '@fortawesome/fontawesome-free-solid/faListUl';
import faLock from '@fortawesome/fontawesome-free-solid/faLock';
import faPlayCircle from '@fortawesome/fontawesome-free-regular/faPlayCircle';
import faPlayCircleSolid from '@fortawesome/fontawesome-free-solid/faPlayCircle';
import faPowerOff from '@fortawesome/fontawesome-free-solid/faPowerOff';
import farBookmark from '@fortawesome/fontawesome-free-regular/faBookmark';
import faRocket from '@fortawesome/fontawesome-free-solid/faRocket';
import faSearch from '@fortawesome/fontawesome-free-solid/faSearch';
import faSignOutAlt from '@fortawesome/fontawesome-free-solid/faSignOutAlt';
import faSort from '@fortawesome/fontawesome-free-solid/faSort';
import faSpinner from '@fortawesome/fontawesome-free-solid/faSpinner';
import faSquare from '@fortawesome/fontawesome-free-regular/faSquare';
import faSquareSolid from '@fortawesome/fontawesome-free-solid/faSquare';
import faStepForward from '@fortawesome/fontawesome-free-solid/faStepForward';
import faTimes from '@fortawesome/fontawesome-free-solid/faTimes';
import faToggleOff from '@fortawesome/fontawesome-free-solid/faToggleOff';
import faToggleOn from '@fortawesome/fontawesome-free-solid/faToggleOn';
import faTrash from '@fortawesome/fontawesome-free-solid/faTrash';
import faTrophy from '@fortawesome/fontawesome-free-solid/faTrophy';
import faUser from '@fortawesome/fontawesome-free-solid/faUser';
import faUserCircle from '@fortawesome/fontawesome-free-solid/faUserCircle';
import faUserPlus from '@fortawesome/fontawesome-free-solid/faUserPlus';
import faUsers from '@fortawesome/fontawesome-free-solid/faUsers';
import faUserTimes from '@fortawesome/fontawesome-free-solid/faUserTimes';
import {faTimesCircle} from '@fortawesome/fontawesome-free-solid';
import {faLightbulbOn} from '@fortawesome/pro-solid-svg-icons';
import {faAnalytics} from '@fortawesome/pro-solid-svg-icons';

export default [
  library.add(
    faAnalytics,
    faLightbulbOn,
    faAmbulance,
    faAngleDown,
    faAngleRight,
    faBars,
    faBell,
    faBook,
    faBookmark,
    faCalendarAlt,
    faCaretDown,
    faCaretUp,
    faChartBar,
    faChartPie,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faChevronRight,
    faCircle,
    faCircleReg,
    faClock,
    faClockSolid,
    faCloudDownloadAlt,
    faCog,
    faDotCircle,
    faEllipsisV,
    faExclamationCircle,
    faExclamationTriangle,
    faExpand,
    faExternalLinkAlt,
    faEye,
    faEyeSlash,
    faFileAlt,
    faFilter,
    faFolder,
    faFolderOpen,
    faGoogle,
    faGraduationCap,
    faHandPointUp,
    faHospitalAlt,
    faHtml5,
    faIdCard,
    faLinkedinIn,
    faList,
    faListUl,
    faLock,
    faPlayCircle,
    faPlayCircleSolid,
    faPowerOff,
    farBookmark,
    faRocket,
    faSearch,
    faSignOutAlt,
    faSort,
    faSpinner,
    faSquare,
    faSquareSolid,
    faStepForward,
    faTimes,
    faTimesCircle,
    faToggleOff,
    faToggleOn,
    faTrash,
    faTrophy,
    faUser,
    faUserCircle,
    faUserPlus,
    faUsers,
    faUserTimes
  )
];
