<template>
  <div id="classeslayout" class="classes ">
    <app-emulation />
    <app-header class="fixed-top" />
    <slot class="h-auto pb-5 align-items-stretch flex-grow-1" />
  </div>
</template>
<script>
import Emulation from '@/components/Emulation.vue';
import Header from '@/components/Header.vue';
import bodyClass from '@/utils/mixins/BodyClass';

export default {
  name: 'ClassesLayout',
  components: {
    'app-emulation': Emulation,
    'app-header': Header,
  },
  mixins: [bodyClass],
  data() {
    return {
      bodyClass: 'body-offwhite',
    };
  },
};
</script>

<style lang="scss"></style>
