const Admin = () => import('@/components/Admin/Admin');
const HealthCheck = () => import('@/components/Admin/HealthCheck');

let defaultLayout = 'AdminLayout';
export default [
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    redirect: { name: 'health' },
    meta: {
      layout: defaultLayout
    },
    children: [
      {
        path: 'drprofessor',
        name: 'health',
        component: HealthCheck,
        meta: {
          layout: defaultLayout
        }
      },
      {
        path: '*',
        redirect: { name: 'Admin' },
        meta: {
          layout: defaultLayout
        }
      }
    ]
  }
];
