/* eslint-disable max-len, no-var */

class Analytics {
  constructor() {
  }

  /**
   *
   * @param {boolean} isSpa // whether this is a single page application
   */
  start(isSpa) {
    // GTM snippets
    const gtmScript = document.createElement('script');
    gtmScript.innerHTML = '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\': new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);})(window,document,\'script\',\'dataLayer\',\'GTM-NJW7GRW\');';
    document.head.appendChild(gtmScript);

    const gtmNoScript = document.createElement('noscript');
    gtmNoScript.innerHTML = '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NJW7GRW" height="0" width="0" style="display:none;visibility:hidden"></iframe>';
    document.body.insertBefore(gtmNoScript, document.body.firstChild);

    // Churnzero
    (function() {
      const cz = document.createElement('script'); cz.type = 'text/javascript';
      cz.async = true;
      cz.src = 'https://analytics.churnzero.net/churnzero.js';
      const s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(cz, s);
    })();

    window.dataLayer = window.dataLayer || []; // GTM
    window.ChurnZero = window.ChurnZero || [];
  }

  setUser(user, churnzeroAPIKey) {
    this.user = {};
    if (!user.is_logged_in) {
      this.user.role = 'guest';
    } else {
      this.user.id = user.id;
      if (user.employee || (user.organization && user.organization.role === 'master_admin')) {
        this.user.role = 'employee';
      } else if (user.organization && user.organization.type === 'academic' && user.organization.role === 'member') {
        this.user.role = 'student';
      } else if (user.organization && user.organization.type === 'academic' && user.organization.role === 'admin') {
        this.user.role = 'instructor';
      } else if (user.organization && user.organization.type === 'enterprise' && user.organization.role === 'member') {
        this.user.role = 'team member';
      } else if (user.organization && user.organization.type === 'enterprise' && user.organization.role === 'admin') {
        this.user.role = 'team admin';
      } else {
        this.user.role = 'individual';
      }
    }

    // Set vars for Churnzero
    if (window.ChurnZero) {
      window.ChurnZero.push(['setAppKey', churnzeroAPIKey]);
    }

    // `salesforce_id` indicates a customer we track, only load if it is present
    if (user.salesforce_id) ChurnZero.push(['setContact', user.salesforce_id, user.id]);


    // set for GTM
    window.dataLayer.push({'user': this.user});
  }

  // See best practices - https://support.google.com/analytics/answer/1033068?hl=en
  sendCustomEvent(category, action, label, value) {
    window.dataLayer.push({
      event: 'eventTracking',
      category: category,
      action: action,
      label: label,
      value: value,
    });
  }
}


const analytics = new Analytics();

export default analytics;
