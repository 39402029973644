import axios from 'axios';

const lessonBuilder = {
  namespaced: true,
  state: {
    name: null,
    code: null,
    subject: null,
    plan: null,
    settings: null
  },
  mutations: {
    setName(state, name) {
      state.name = name;
    },
    setCode(state, code) {
      state.code = code;
    },
    setSubject(state, subject) {
      state.subject = subject;
    },
    setPlan(state, val) {
      state.plan = val;
    },
    setSettings(state, val) {
      state.settings = val;
    }
  },
  actions: {
    /**
     * Create multigroup with assignments JCS07202018
     * @param {object} plan object
     * @returns Promise
     * @param data
     */
    createMultigroup({ state, commit }, data) {
      // call API and wait for response MGID to send to page
      return new Promise((resolve, reject) => {
        axios.post(process.env.SPENV.APP_URL + '/api/v2/multigroups', data, {withCredentials: true}).then(
            (response) => {
            resolve(response.data);
          })
          .catch(
            (error) => {
            reject(error);
          });
      });
    },
    setSubject ({ commit }, data) {
      commit('setSubject', data);
    }
  },
  getters: {
    getName(state) {
      return state.name;
    },
    getCode(state) {
      return state.code;
    },
    getSubject(state) {
      return state.subject;
    },
    getPlan(state) {
      return state.plan;
    }
  }
};
export default lessonBuilder;
