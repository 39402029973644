<template>
  <div id="header">
    <b-navbar toggleable="lg" type="light" class="bg-white">
      <!-- Branding -->
      <!-- TODO: Convert into component -->
      <b-navbar-brand :href="getBrandingUrl()">
        <b-img
          :src="getBranding()"
          class="c-logo"
          alt="SolidProfessor"
          @error="onError" />
      </b-navbar-brand>
      <!-- Small Display Search -->
      <b-nav-form class="d-lg-none form-search compact-search mx-4">
        <b-input-group id="mobileSearch" class="search-group">
          <b-form-input
            v-model="searchTerm"
            type="search"
            class="search-form"
            placeholder="Find answers faster!" />
          <b-button
            slot="append"
            variant="outline-primary"
            type="submit"
            @click="search">
            <font-awesome-icon icon="search" />
          </b-button>
        </b-input-group>
      </b-nav-form>
      <b-navbar-toggle target="nav_collapse" />
      <b-collapse id="nav_collapse" is-nav>
        <b-navbar-nav type="light" class="nav_left">
          <!-- MegaDropDown  -->
          <b-dropdown
            id="megadropdown"
            ref="megadropdown"
            variant="outline-primary"
            text="Library "
            class="mr-2 d-none d-lg-block">
            <b-row v-if="library" class="no-gutters dropdown mega-dropdown">
              <b-col cols="12" sm="4" class="level-1">
                <b-nav vertical>
                  <b-nav-item
                    v-for="software in library.slice(0, 10)"
                    :key="software.id"
                    :active="software.hovered"
                    class="border-0 py-0 pl-2"
                    :to="{ name: 'software', params: { swslug: software.slug } }">
                    <div
                      slot="button-content"
                      @mouseout="software.hovered = false"
                      @mouseover="find_software(software.id); software.hovered = true;">
                      <span class="text-center icon-container">
                        <b-img-lazy
                          :src=" SPENV.S3_URL + '/images/software-thumbs/sw-logo-' + software.id + '.png' "
                          @error="onError" />
                      </span> {{ software.name }}
                    </div>
                  </b-nav-item>
                  <b-nav-item variant="light" :to="{ name: 'library' }"
                              @click="closeMegaDrop()">
                    View all
                    <font-awesome-icon class="align-baseline" size="xs" :icon="['fas', 'chevron-right']" />
                  </b-nav-item>
                </b-nav>
              </b-col>
              <b-col cols="12" sm="4" class="bg-white submenu-options">
                <b-nav-item
                  v-for="course in course_library.course_list_left"
                  :key="course.id"
                  :to="{ name: 'course', params: { swslug: course.softwareSlug, courseslug: course.slug } }">
                  {{ course.title }}
                </b-nav-item>
                <b-nav-item
                  v-if="course_library.course_list_left.length && course_library.course_list_right.length === 0"
                  :to="{ name: 'software', params: { swslug: course_library.software_slug } }"
                  @click="closeMegaDrop()">
                  <span class="font-weight-bold">View all</span>
                  <font-awesome-icon class="align-baseline" size="xs" :icon="['fas', 'chevron-right']" />
                </b-nav-item>
              </b-col>
              <b-col cols="12" sm="4" class="bg-white submenu-options">
                <b-nav-item
                  v-for="course in course_library.course_list_right"
                  :key="course.id"
                  :to="{ name: 'course', params: { swslug: course.softwareSlug, courseslug: course.slug }}">
                  {{ course.title }}
                </b-nav-item>
                <b-nav-item
                  v-if="course_library.course_list_right.length && course_library.software_slug"
                  :to="{ name: 'software', params: { swslug: course_library.software_slug } }"
                  @click="closeMegaDrop()">
                  <span class="font-weight-bolder">View all</span>
                  <font-awesome-icon class="align-baseline" size="xs" :icon="['fas', 'chevron-right']" />
                </b-nav-item>
              </b-col>
            </b-row>
          </b-dropdown>
          <!-- MegaDropDown  end -->

          <!-- Large Display Search -->
          <b-nav-form class="d-none d-lg-block form-search ml-1">
            <b-input-group class="search-group ">
              <b-form-input
                v-model="searchTerm"
                type="search"
                class="form-control search-form"
                placeholder="Find answers fast!"
                autocomplete="off"
                @input="autocomplete" />
              <b-button
                slot="append"
                variant="outline-primary"
                type="submit"
                @click="search">
                <font-awesome-icon icon="search" />
              </b-button>
            </b-input-group>

            <!-- Autocomplete Results -->
            <div v-if="autocompleteResults.length > 0 && searchTerm" class="autocomplete_container">
              <b-list-group class="position-fixed list-group-autocomplete">
                <b-list-group-item v-for="top in topResults" :key="top.id" class="result text-truncate">
                  <b-link href="#" class="no-hover" @click="goToResult(SPENV.WWW_URL + '/' + SPENV.LIBRARY_FOLDER_DISPLAY + top.lesson_url)">
                    <font-awesome-icon class="mr-2" :icon="['far', 'file-alt']" size="lg" />
                    {{ top.software }} {{ top.year }} {{ top.title | striphtml }}
                  </b-link>
                </b-list-group-item>
                <b-list-group-item v-show="autocompleteResults.length > 0" class="py-3 text-muted">
                  <small><strong>COURSES</strong></small>
                </b-list-group-item>
                <b-list-group-item v-for="result in autocompleteResults" :key="result.id" class="result">
                  <b-link href="#" class="no-hover" @click="goToResult(SPENV.WWW_URL + '/' + SPENV.LIBRARY_FOLDER_DISPLAY + result.url)">
                    <font-awesome-icon class="mr-2" icon="book" size="lg" />
                    {{ result.name }}
                  </b-link>
                </b-list-group-item>
                <b-list-group-item v-show="autocompleteResults.length > 0" class="pt-3 text-primary-green hover-pointer" @click="search">
                  All Results
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-nav-form>

          <!-- Small Display Dropdown Menu -->
          <b-nav-item v-if="!isLoggedIn" class="d-lg-none" :to="{ name: 'Login'}">
            Log in
          </b-nav-item>
          <b-nav-item v-if="!isLoggedIn" class="d-lg-none" :to="{ name: 'AccountSignup'}">
            Buy now
          </b-nav-item>
          <b-nav-item
            v-if="isLoggedIn"
            class="d-lg-none"
            @click="expandSubmenu">
            <b-img-lazy
              v-if="user.avatar"
              id="avatar"
              :src="user.avatar"
              fluid
              rounded="circle"
              width="30"
              height="30" />
            <font-awesome-icon v-else icon="user-circle" size="lg" />
            <span v-if="user.first_name" class="ml-2">Hi {{ user.first_name }}</span>
            <span v-if="!user.first_name" class="ml-2">Hi {{ user.email }}</span>
            <span class="float-right">
              <font-awesome-icon :icon="isMobileExpanded ? 'angle-down' : 'angle-right'" />
            </span>
          </b-nav-item>
          <b-collapse v-if="isLoggedIn" id="user_collapse">
            <b-nav-item v-if="isEmployee" class="d-lg-none" :href="SPENV.ADMIN_PANEL_URL + '/backend/new/default.asp'">
              SP Admin Panel
            </b-nav-item>
            <b-nav-item v-if="isEmployee" class="d-lg-none" :href="SPENV.APP_URL + '/ola'">
              Online Affiliates
            </b-nav-item>
            <b-nav-item
              v-if="user.organization && user.organization.role !== 'member'"
              class="d-lg-none"
              @click="openAdminTools">
              <span v-if="isEnterprise">Admin </span> <span v-else>Instructor </span> Tools
            </b-nav-item>
            <b-nav-item
              v-if="user.organization && user.organization.role !== 'master_admin'"
              class="d-lg-none"
              :href="SPENV.APP_URL + '/lmsapp/documents/list'">
              Documents
            </b-nav-item>
            <b-nav-item
              v-if="user.organization && user.organization.role !== 'member'"
              class="d-lg-none"
              href="https://www.dropbox.com/sh/hsf97klrg1os2on/AACJxBDqBRMbUH-_ukkiAU07a?dl=0"
              target="_blank">
              Practice Exercises
            </b-nav-item>
            <b-nav-item class="d-lg-none" :href="SPENV.APP_URL + '/lmsapp/profile'">
              Profile
            </b-nav-item>
            <b-nav-item
              v-if="!isLTIUser"
              class="d-lg-none"
              :href=" SPENV.APP_URL + '/lmsapp/profile/membership/billing'">
              Membership details
            </b-nav-item>
            <b-nav-item class="d-lg-none" :href="SPENV.APP_URL + '/lmsapp/achievements'">
              Achievements
            </b-nav-item>
            <b-nav-item
              v-if="isAcademic && !isLTIUser"
              class="d-lg-none"
              :href="SPENV.APP_URL + '/lmsapp/profile/changeSchool'">
              Change school
            </b-nav-item>
            <b-nav-item
              v-if="isAcademic && !isLTIUser"
              class="d-lg-none" :href="SPENV.APP_URL + '/lmsapp/profile/leaveSchool'">
              Leave school
            </b-nav-item>
            <b-nav-item class="d-lg-none" href="https://success.solidprofessor.com/knowledge-base">
              Knowledge Base
            </b-nav-item>
          </b-collapse>
          <b-nav-item v-if="isLoggedIn" class="ml-3" :href="SPENV.APP_URL">
            Dashboard
          </b-nav-item>
          <b-nav-item
            v-if="isLoggedIn && user.organization"
            :href="SPENV.APP_URL + '/lmsapp/multigroups/list/'">
            <span v-if="isAcademic">Classes</span><span v-else>Groups</span>
          </b-nav-item>
          <b-nav-item
            class="d-lg-none"
            :href="'/' + SPENV.LIBRARY_FOLDER_DISPLAY"
            :class="{ active: isRouteInLibrary }">
            Library
          </b-nav-item>
          <b-nav-item
            v-if="isLoggedIn && user.organization && user.organization.role !== 'member'"
            :href="SPENV.APP_URL + '/lmsapp/reporting/'"
            class="mr-0">
            Reporting
          </b-nav-item>
          <b-nav-item
            v-if="isLoggedIn && !isUserEmulation"
            class="d-lg-none"
            href="javascript:void(0)"
            @click="logout()">
            Log out
          </b-nav-item>
          <b-nav-item
            v-if="isUserEmulation"
            class="d-lg-none"
            :href="SPENV.ACCOUNTS_API_URL + '/logout-as'">
            End 'Login as'
          </b-nav-item>
          <!-- Small Display Dropdown Menu end -->
        </b-navbar-nav>
        <!-- Large Display - Right Login/Buy buttons -->
        <b-navbar-nav v-if="!isLoggedIn" class="ml-auto d-none d-lg-flex" type="light">
          <li class="nav-item">
            <b-button id="login" variant="outline-primary" :to="{ name: 'Login' , query: { return_url: SPENV.WWW_URL + $route.path }}">
              Log in
            </b-button>
          </li>
          <li class="nav-item mr-0">
            <b-button
              id="signup"
              variant="primary"
              class="mr-0"
              :to="{ name: 'AccountSignup'}">
              Buy now
            </b-button>
          </li>
        </b-navbar-nav>
        <b-navbar-nav v-if="isLoggedIn" class="ml-auto d-none d-lg-flex" type="light">
          <!-- Large Display - Right User menu -->
          <b-nav-item-dropdown right>
            <template slot="button-content">
              <!-- TODO: User function to get name -->
              <!-- TODO: Make user display component -->
              <span v-if="user.first_name" class="user-menu-name mr-2">Hi {{ user.first_name }}</span>
              <span v-if="!user.first_name" class="user-menu-email mr-2">{{ user.email }}</span>
              <b-img-lazy
                v-if="user.avatar"
                id="avatar"
                :src="user.avatar"
                fluid rounded="circle" width="30"
                height="30" />
              <font-awesome-icon
                v-else icon="user-circle"
                class="pl-1"
                size="lg" />
            </template>
            <b-dropdown-item v-if="isEmployee" :href="SPENV.ADMIN_PANEL_URL + '/backend/new/default.asp'">
              <font-awesome-icon
                fixed-width icon="cog"
                class="pr-1"
                size="lg" />
              SP Admin Panel
            </b-dropdown-item>
            <b-dropdown-item v-if="isEmployee" :href="SPENV.APP_URL + '/ola'">
              <font-awesome-icon
                fixed-width
                size="lg"
                class="pr-1"
                icon="chart-pie" />
              Online Affiliates
            </b-dropdown-item>
            <b-dropdown-item v-if="user.organization && user.organization.role !== 'member'" @click="openAdminTools">
              <font-awesome-icon
                fixed-width
                size="lg"
                class="pr-1"
                icon="users" />
              <span v-if="isEnterprise">Admin </span>
              <span v-else>Instructor </span> Tools
            </b-dropdown-item>
            <b-dropdown-divider v-if="(user.organization && user.organization.role !== 'member') || isEmployee" />
            <b-dropdown-item
              v-if="user.organization && user.organization.role !== 'master_admin' "
              :href="SPENV.APP_URL + '/lmsapp/documents/list'">
              <font-awesome-icon
                fixed-width
                size="lg"
                class="pr-1"
                :icon="['far', 'file-alt']" />
              Documents
            </b-dropdown-item>
            <!-- TODO: Move link to config -->
            <b-dropdown-item
              v-if="user.organization && user.organization.role !== 'member'"
              href="https://www.dropbox.com/sh/hsf97klrg1os2on/AACJxBDqBRMbUH-_ukkiAU07a?dl=0"
              target="_blank">
              <font-awesome-icon
                fixed-width size="lg"
                class="pr-1"
                :icon="['far', 'folder']" />
              Practice Exercises
            </b-dropdown-item>
            <b-dropdown-item :href="SPENV.APP_URL + '/lmsapp/profile'">
              <font-awesome-icon
                fixed-width size="lg"
                class="pr-1"
                icon="user" />
              Profile
            </b-dropdown-item>
            <b-dropdown-item v-if="!isLTIUser" :href=" SPENV.APP_URL + '/lmsapp/profile/membership/billing'">
              <font-awesome-icon
                fixed-width
                size="lg"
                class="pr-1"
                :icon="['far', 'id-card']" />
              Membership details
            </b-dropdown-item>
            <b-dropdown-item :href="SPENV.APP_URL + '/lmsapp/achievements'">
              <font-awesome-icon
                fixed-width size="lg"
                class="pr-1"
                icon="trophy" />
              Achievements
            </b-dropdown-item>
            <b-dropdown-item
              v-if=" user.organization && isAcademic && user.organization.role !== 'master_admin' && !isLTIUser"
              :href="SPENV.APP_URL + '/lmsapp/profile/changeSchool'">
              <font-awesome-icon
                fixed-width size="lg"
                class="pr-1"
                icon="graduation-cap" />
              Change school...
            </b-dropdown-item>
            <b-dropdown-item
              v-if=" user.organization && isAcademic && user.organization.role !== 'master_admin' && !isLTIUser"
              :href="SPENV.APP_URL + '/lmsapp/profile/leaveSchool'">
              <font-awesome-icon
                fixed-width size="lg"
                class="pr-1"
                icon="sign-out-alt" />
              Leave school...
            </b-dropdown-item>
            <b-dropdown-item :href="SPENV.SUPPORT_URL">
              <font-awesome-icon
                fixed-width size="lg"
                class="pr-1"
                icon="external-link-alt" />
              Knowledge Base
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item v-if="isLoggedIn && !isUserEmulation" @click="logout()">
              <font-awesome-icon
                fixed-width size="lg"
                class="pr-1"
                icon="power-off" />
              Log out
            </b-dropdown-item>
            <b-dropdown-item v-if="isUserEmulation" :href="SPENV.ACCOUNTS_API_URL + '/logout-as'">
              <font-awesome-icon
                fixed-width size="lg"
                class="pr-1"
                icon="power-off" />
              End 'Login as'
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <!-- Large Display - Right User menu  end -->
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div v-if="alertBarMessage">
      <b-alert variant="warning" class="rounded-0" show
               dismissible>
        <font-awesome-icon icon="exclamation-triangle" class="mr-1" />
        {{ alertBarMessage }}
      </b-alert>
    </div>
  </div>
</template>

<script>
import analyticsClickHandler from '@/utils/mixins/Analytics';
import SearchUtility from '@/utils/SearchUtilities.js';
import jsonp from 'jsonp';
import axios from 'axios';
import _ from 'lodash';
import redirectMixin from '@/utils/mixins/Redirect';
import zendesk from '@/../../shared/js/zendesk.js';

export default {
  name: 'Header',
  mixins: [analyticsClickHandler, redirectMixin, zendesk],
  beforeRouteLeave(to, from, next) {
    // Remove autocomplete results and search term
    this.resetAutocomplete();
    next();
  },
  data() {
    return {
      course_library: {
        software_slug: null,
        course_list_left: [],
        course_list_right: [],
      },
      searchTerm: null,
      loadingUser: false,
      isMobileExpanded: false,
      isUserMenuOpen: false,
      courseIDs: [],
      alertBarMessage: undefined,
      searchUrl:
        'https://dyb8n.api.searchify.com/v1/indexes/' +
        process.env.SPENV.SEARCHIFY_INDEX +
        '/search?fetch=name,docid,title,description,thumbnail,url,lesson_url,course,course_url,section_id,section,lesson_script,course_id,software_id,software,software_url,content_type"',
      topResults: [],
      autocompleteResults: [],
    };
  },
  computed: {
    library () {
      if (this.$store.getters['header/getLibrary']) {
        return this.$store.getters['header/getLibrary'];
      } else {
        return false;
      }
    },
    isRouteInLibrary () {
      return (
        this.$route.path.indexOf('/' + this.SPENV.LIBRARY_FOLDER_DISPLAY) === 0
      );
    },
    showSubMenuBar (){
      return this.$store.getters['header/getSubMenuStatus'];
    }
  },
  watch: {
    // Hide dropdown
    $route(to, from) {
      this.closeMegaDrop();
      this.resetAutocomplete();
    },
    searchTerm: function(oldVal, newVal) {
      if (oldVal === '' || newVal === '') {
        this.resetAutocomplete();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init () {
      if (!this.user || !this.library) {
        this.loadingUser = true;
        this.$store.dispatch('header/loadHeaderData').then(
          (response) => {
            this.loadingUser = false;
            if (this.isLoggedIn) {
              this.analyticsSetUser(this.user, this.SPENV.CHURNZERO_API_KEY);
            }
            let firstSoftware = this.$store.getters['header/getLibrary'][0];
            // Select first software
            this.find_software(firstSoftware.id);
            this.getCourseIds();
            this.$store.dispatch('search/findSearchWeights');
          })
          .catch(
            (error) => {
              console.log(error);
            });
      }
      this.searchTerm = this.$route.query.q;
      this.spGlobalAlerts();
    },
    // finds all courses associated with a software ID, passes them to format EJL050118
    find_software(softwareId) {
      let software = _.find(this.library, {id: softwareId});
      if (software) {
        this.course_library.course_list_left = [];
        this.course_library.course_list_right = [];
        this.course_library.software_slug = software.slug;
        this.format_course_list(software.courses, software);
      }
    },
    // separates courses and divides into 2 arrays to support 2 column layout EJL050118
    format_course_list(courseList, software) {
      let limitCourses = courseList.length < 20 ? courseList.length : 20;
      for (let k = 0; k < limitCourses; k++) {
        courseList[k].softwareSlug = software.slug;
        if (k < 10) {
          this.course_library.course_list_left.push(courseList[k]);
        } else {
          this.course_library.course_list_right.push(courseList[k]);
        }
      }
    },
    // fetches search term and appends to query string, appends to proper search form url
    search(event) {
      event.preventDefault();
      let queryString = this.searchTerm;
      if (queryString) {
        if (this.$route.name === 'Results') {
          // if already in the vue app, we don't need to reload the page
          // just update the query variable
          this.resetAutocomplete();
        }
        this.$router.push({name: 'Results', query: {q: queryString}}).catch(err => {});
      }
    },
    spGlobalAlerts() {
      this.alertBarMessage = window.spGlobalAlert;
      if (this.alertBarMessage === undefined) {
        setTimeout(function(){
          this.spGlobalAlerts();
        }.bind(this), 1000);
      }
    },
    getCourseIds() {
      axios.get(this.SPENV.APP_URL + '/api/v2/course/ids', {withCredentials: true}).then(
        (response) => {
          this.courseIDs = response.data;
        },
        (error) => {
          console.log(error);
        },
      );
    },
    formatTopResults(results) {
      for (let i = 0; i < results.length; i++) {
        if (/^\d+$/.test(results[i].course_url.slice(-4))) {
          results[i].year = results[i].course_url.slice(-4);
        } else {
          results[i].year = null;
        }
      }
      return results;
    },
    autocomplete: _.debounce(function(term) {
      let url = this.searchUrl + '&len=20&q=' + SearchUtility.buildQuery(term, this.courseIDs, this.$store.getters['search/getSearchWeights']);
      let opts = {};
      return new Promise((resolve, reject) => {
        jsonp(url, opts, (err, data) => {
          let courseList = [];
          if(data && data.results && data.results.length > 0) {
            this.topResults = this.formatTopResults(data.results.slice(0, 2));
            let courses = _.groupBy(data.results, 'course_id');
            _.map(courses, function (course) {
              courseList.push({
                id: course[0].course_id,
                name: course[0].course,
                url: course[0].course_url,
                software: course[0].software,
                software_url: course[0].software_url,
                score: course[0].query_relevance_score,
              });
              return courseList;
            });
          }
          this.autocompleteResults = _.orderBy(courseList, ['score'], ['desc']).slice(0, 5);
          resolve(data);
          if (err) {
            console.log(err);
          }
        });
      });
    }, 100),
    expandSubmenu(event) {
      event.stopPropagation();
      this.$root.$emit('bv::toggle::collapse', 'user_collapse');
      this.isMobileExpanded = !this.isMobileExpanded;
    },
    onError(e) {
      let defaultURL =
        this.SPENV.S3_URL + '/images/logos/sp-logo.png';
      if (e.target.src !== defaultURL) {
        e.target.src = defaultURL;
      }
    },
    /**
     * Opens a new window with the admin tools
     */
    openAdminTools() {
      let groupType = 'classes';
      if (this.isEnterprise) {
        groupType = 'groups';
      }
      let adminUrl = '/' + groupType + '/admin';
      this.$router.push({path: adminUrl}).catch(err => {});
    },
    /**
     * User logout handler jhamm 6/4/18
     */
    logout() {
      this.redirectUrl(this.SPENV.ACCOUNTS_API_URL + '/logout');
    },
    getBranding () {
      let srcUrl = this.SPENV.S3_URL + '/images/logos/sp-logo.png';
      if (this.isLoggedIn && this.user.affiliate) {
        srcUrl = this.user.affiliate.logo_url;
      }
      return srcUrl;
    },
    getBrandingUrl () {
      let url = '/';
      if (this.isLoggedIn) {
        url = this.SPENV.APP_URL + '/lmsapp/dashboard';
      }
      return url;
    },
    /**
     * Call store to save the search keyword to the db and then go to URL where of the autocomplete result
     */
    goToResult (url) {
      this.analyticsClickEvent('Search', 'Click result', 'search_term: ' + this.searchTerm + ', url: ' + url);
      SearchUtility.saveSearchKeyword(this.searchTerm).then(
        (response) => {
          this.redirectUrl(url);
        });
    },
    closeMegaDrop() {
      if (this.$refs.megadropdown) {
        this.$refs.megadropdown.hide(true);
      }
    },
    resetAutocomplete() {
      this.topResults = [];
      this.autocompleteResults = [];
      this.searchTerm = null;
    },
  },

};
</script>

<style lang="scss">
</style>
