import axios from 'axios';

const search = {
  namespaced: true,
  state: {
    search_weights: [],
    loaded: false
  },
  mutations: {
    setSearchWeights(state, val) {
      state.search_weights = val;
    },
    setSearchWeightsLoaded(state, val) {
      state.loaded = val;
    }
  },
  actions: {
    /**
     * Get search weights from db table jcervantes 07/18/19
     * @returns Promise
     */
    findSearchWeights({ state, commit }) {
      return new Promise((resolve, reject) => {
        axios.get(process.env.SPENV.APP_URL + '/api/v2/settings/search-weights', { withCredentials: true }).then(
          (response) => {
              commit('setSearchWeights', response.data);
              commit('setSearchWeightsLoaded', true);
              resolve(response.data);
            },
          (error) => {
              commit('setSearchWeightsLoaded', false);
              reject(error);
            }
          );
      });
    }
  },
  getters: {
    getSearchWeights(state) {
      return state.search_weights;
    },
    getSearchWeightsLoaded(state) {
      return state.loaded;
    }
  }
};

export default search;
