import Vue from 'vue';
import axios from 'axios';

const paths = {
  namespaced: true,
  state: {
    pathList: [],
    pathsLoaded: false
  },
  mutations: {
    setpathList(state, paths) {
      let details = paths[0];
      let progress = paths[1];
      let pathList = [];
      // Set initial details for path in new array
      for (let i = 0; i < details.length; i++) {
        pathList.push(details[i]);
      }
      // Find existing path and add progress
      for (let j = 0; j < progress.length; j++) {
        for (let k = 0; k < pathList.length; k++) {
          if (progress[j].path_id === pathList[k].path_id) {
            Vue.set(pathList[k], 'progress', progress[j].progress);
            break;
          }
        }
      }
      state.pathList = pathList;
    },
    setPathsLoaded(state, val) {
      state.pathsLoaded = val;
    },
    setPathDetails(state, path) {
      let details = path[0];
      let progress = path[1];
      // Add course progress to learning path object jhamm 9/20/18
      function combine() {
        for (let i = 0; i < details.courses.length; i++) {
          for (let k = 0; k < progress.length; k++) {
            if (details.courses[i].drmid === progress[k].drmid) {
              Vue.set(details.courses[i], 'progress', progress[k].progress);
            }
          }
        }
        return details;
      }
      if (state.pathList.length === 0) {
        state.pathList.push(combine());
      }
      let found = false;
      for (let m = 0; m < state.pathList.length; m++) {
        if (state.pathList[m].path_id === details.path_id) {
          Vue.set(state.pathList, m, Object.assign(state.pathList[m], combine()));
          found = true;
          break;
        }
      }
      if (!found) {
        state.pathList.push(combine());
      }
    },
    setPathProgress(state, data) {
      // Find path by id
      let selectedPath = null;
      for (let i = 0; i < state.pathList.length; i++) {
        if (state.pathList[i].slug === data.path_slug) {
          selectedPath = state.pathList[i];
        }
      }
      // Update course progress for selected path
      for (let i = 0; i < selectedPath.courses.length; i++) {
        for (let k = 0; k < data.progress.length; k++) {
          if (selectedPath.courses[i].drmid === data.progress[k].drmid) {
            Vue.set(selectedPath.courses[i], 'progress', data.progress[k].progress);
          }
        }
      }
    }
  },
  actions: {
    /**
     * Get all learning paths
     * @param {obj}
     * @returns Promise
     */
    loadPathList({ state, commit }) {
      return new Promise((resolve, reject) => {
        axios.get(process.env.SPENV.APP_URL + '/api/v2/paths', {withCredentials: true}).then(
            (response) => {
            resolve(response.data);
          })
          .catch(
            (error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    loadPathListProgress({ state, commit }) {
      return new Promise((resolve, reject) => {
        axios.get(process.env.SPENV.APP_URL + '/api/v2/user/paths/progress', {withCredentials: true}).then(
            (response) => {
            resolve(response.data);
          })
          .catch(
            (error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    /**
     * Get learning path by id
     * @param {obj}
     * @returns Promise
     * @param slug
     */
    loadPathBySlug({ state, commit }, slug) {
      return new Promise((resolve, reject) => {
        axios.get(process.env.SPENV.APP_URL + '/api/v2/path/' + slug, {withCredentials: true}).then(
            (response) => {
            resolve(response.data);
          })
          .catch(
            (error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    loadPathProgressBySlug({ state, commit }, slug) {
      return new Promise((resolve, reject) => {
        axios.get(process.env.SPENV.APP_URL + '/api/v2/user/path/' + slug + '/progress', { withCredentials: true }).then(
            (response) => {
            resolve(response.data);
          })
          .catch(
            (error) => {
            console.log(error);
            reject(error);
          });
      });
    }
  },
  getters: {
    getPathList(state) {
      return state.pathList;
    },
    getPathsLoaded(state) {
      return state.pathsLoaded;
    },
    getPath(state) {
      return function(slug) {
        for (let a = 0; a < state.pathList.length; a++) {
          if (state.pathList[a].slug === slug) {
            return state.pathList[a];
          }
        }
        return null;
      };
    }
  }
};
export default paths;
