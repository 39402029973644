<template>
  <div id="app">
    <LayoutBroker :layouts="$options.availableLayouts" :current="$route.meta.layout" />
  </div>
</template>
<script>
import LayoutBroker from '@/layouts/LayoutBroker';
import Base from '@/layouts/Base';
import ClassesLayout from '@/layouts/ClassesLayout';
import AdminLayout from '@/layouts/AdminLayout';
import AccountLayout from '@/layouts/AccountLayout';
import SignupLayout from '@/layouts/SignupLayout';
import SkillsgapLayout from '@/layouts/SkillsgapLayout';
import StudentStoreLayout from '@/layouts/StudentStoreLayout';

const availableLayouts = {
  Base,
  ClassesLayout,
  AdminLayout,
  AccountLayout,
  SignupLayout,
  SkillsgapLayout,
  StudentStoreLayout
};

export default {
  name: 'App',
  metaInfo: {
    link: [
      { rel: 'icon', href: process.env.SPENV.CDN_URL + '/icon/favicon.ico' },
    ]
  },
  components: {
    LayoutBroker
  },
  availableLayouts
};
</script>

<style lang="scss">

  .preserve-word {
    word-break: normal !important;
  }

</style>
