<template>
  <div>
    <header class="navbar navbar-light bg-white fixed-top">
      <a class="navbar-brand" :href="SPENV.WWW_URL">
        <b-img-lazy :src="SPENV.S3_URL + '/images/logos/sp-logo.png'" class="c-logo " alt="SolidProfessor logo" />
      </a>
    </header>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'StudentStoreLayout',
};
</script>

<style lang="scss" scoped>
  .sub-nav-padding {
    padding-top: 100px !important;
  }

  .nav-tabs-material {
    .nav-link {
      font-weight: 400 !important;
    }
  }
</style>
