import Vue from 'vue';
import Vuex from 'vuex';

import admin from './modules/admin';
import header from './modules/header';
import healthcheck from './modules/healthcheck';
import lessonBuilder from './modules/lessonBuilder';
import multigroup from './modules/multigroup';
import params from './modules/params';
import paths from './modules/learningPaths';
import plans from './modules/plans';
import player from './modules/player';
import sptests from './modules/sptests';
import software from './modules/software';
import studentStore from './modules/student-store';
import user from './modules/user';
import users from './modules/users';
import search from './modules/search';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    admin,
    header,
    healthcheck,
    lessonBuilder,
    multigroup,
    params,
    paths,
    plans,
    player,
    sptests,
    software,
    studentStore,
    user,
    users,
    search
  }
});
