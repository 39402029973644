const redirectMixin = {
  methods: {
    redirectUrl: function(url) {
      let goUrl = url || this.SPENV.APP_URL + '/lmsapp';
      const link = this.$router.resolve({
        name: url,
      });
      if (link && link.href !== '/') {
        return this.$router.push({'name': url}).catch(err => {});
      } else if (_.isObject(url)) {
        if (_.has(url, 'data.return_url')) {
          goUrl = url.data.return_url;
        }
      }
      window.location = goUrl;
    },
  },
};

export default redirectMixin;
