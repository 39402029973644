import axios from 'axios';

const studentStore = {
  namespaced: true,
  state: {
    SPENV: process.env.SPENV,
    email: null,
    existingSchool: false,
    order: null,
    schools: [],
    selectedSchool: null,
    stripeCardToken: null,
    step: 1, // (int) gate required to 'step' through the nav tabs, range 1 - 3
    user: null,
    first_name: null,
    last_name: null,
    userOrigin: null // student store path (standard or LTI)
  },
  mutations: {
    setName (state, name) {
      state.first_name = name;
    },
    setLastName (state, lastName) {
      state.last_name = lastName;
    },
    setStripeCardToken (state, data) {
      state.stripeCardToken = data;
    },
    setOrder (state, order) {
      state.order = order;
    },
    setSchools (state, schools) {
      state.schools = schools;
    },
    setStudentStoreSchools (state, schools) {
      state.schools = schools;
    },
    setSelectedSchool (state, school) {
      state.selectedSchool = school;
    },
    setExistingSchool (state, school) {
      state.existingSchool = school;
    },
    setStep (state, int) {
      state.step = int;
    },
    setUserOrigin (state, userOrigin) {
      state.userOrigin = userOrigin;
    }
  },
  actions: {
    /**
     * Get existing customer from Stripe API
     * https://stripe.com/docs/api/customers/retrieve
     * @param {string} idcustomer SolidProfessor idcustomer
     *
     * @resolve {response} -- on a successful resolution, will set response.data to store
     * @reject {error}
     */
    getStripeCustomer ({ state, commit }, idcustomer) {
      return new Promise(function (resolve, reject) {
        axios({
          method: 'GET',
          url: state.SPENV.APP_API_URL + '/stripe/customer/' + idcustomer,
          headers: {'Content-Type': 'application/json'},
          withCredentials: true
        }).then(
          response => {
            commit('user/setStripeUser', response.data, { root: true });
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    /**
     * Creates a new customer with Stripe via API
     * https://stripe.com/docs/api/customers/create
     * @param {associative array} data i.e.{'customerId': customer.id, 'cardToken': card_token}
     *
     * @resolve {response} -- on a successful resolution, will set response.data to store
     * @reject {error}
     */
    createStripeCustomer ({ state, commit }, data) {
      return new Promise(function (resolve, reject) {
        axios({
          method: 'POST',
          url: state.SPENV.APP_API_URL + '/stripe/customer',
          headers: {'Content-Type': 'application/json'},
          data: data,
          withCredentials: true
        }).then(
          response => {
            commit('user/setStripeUser', response.data, { root: true });
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    /**
     * Uses SolidProfessor API as passthrough to update a Stripe customer with Stripe API
     *
     * @data {object}  -- response body, See `v2->MembershipController->updateStripeCustomer()` for specific details
     * @return {Promise}
     */
    updateStripeCustomer ({ state, commit }, data) {
      return new Promise(function (resolve, reject) {
        axios({
          method: 'PATCH',
          url: state.SPENV.APP_API_URL + '/stripe/customer',
          headers: {'Content-Type': 'application/json'},
          data: data,
          withCredentials: true
        }).then(
          response => {
            if (response.status == 200){
              commit('user/setStripeUser', response.data.data, { root: true });
            }
            resolve(response);
          },
          error => {
            reject(error.response.data);
          }
        );
      });
    },
    /**
     * Charges submitted credit card
     * @param {associative array} data (built in StudentPayment.vue)
     *  {
     *    email: this.email,
     *    password: this.password,
     *    path: this.path,
     *    stripeToken: this.stripeUser.active_card.id,
     *    adminid: this.school.rowid,
     *    stripeId: this.stripeUser.id
     *  }
     *
     * @resolve {response}
     * @reject {error}
     */
    chargeCreditCard ({state}, data) {
      return new Promise(function (resolve, reject) {
        axios({
          method: 'POST',
          url: state.SPENV.APP_API_URL + '/membership/card',
          headers: {'Content-Type': 'application/json'},
          data: data,
          withCredentials: true
        }).then(
          response => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    /**
     * Agnostic function to handle ALL axios API calls
     * using the set mutation
     * @param object
     * @returns Promise
     */
    connectToAPI ({ state, commit }, data) {
      return new Promise(function (resolve, reject) {
        axios({
          method: data['method'],
          url: data['url'],
          data: data['body'],
          headers: {'Content-Type': 'application/json'},
          withCredentials: true
        }).then(
          response => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    /**
     * Logs user in via submitted username/password
     * @param state
     * @param commit
     * @param data
     * @returns {Promise<any>}
     */
    doLogin ({ state, commit }, data) {
      return new Promise(function (resolve, reject) {
        axios({
          method: 'POST',
          url: state.SPENV.ACCOUNTS_API_URL + '/login',
          headers: {'Content-Type': 'application/json'},
          data: data,
          withCredentials: true
        }).then(
          response => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    /**
     * Merges accounts provided in `data`
     * @param state
     * @param commit
     * @param data
     * @returns {Promise<any>}
     */
    mergeLTIAccount ({ state, commit }, data) {
      return new Promise(function (resolve, reject) {
        axios({
          method: 'POST',
          url: state.SPENV.ACCOUNTS_API_URL + '/lti/user/reassign',
          headers: {'Content-Type': 'application/json'},
          data: data,
          withCredentials: true
        }).then(
          response => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    /**
     * Filters the logged in customer's email
     * to see if it is an anonymous user and commits to path standard/lti
     * @param customer object
     */
    getOriginFromUser ({ state, commit }, user) {
      return new Promise(function () {
        let storePath = 'standard';
        if (user && user.email) {
          if (user.isLTIUser) {
            storePath = 'lti';
          }
        }
        commit('setUserOrigin', storePath);
      });
    }
  },
  getters: {
    getName (state) {
      return state.first_name;
    },
    getLastName (state) {
      return state.last_name;
    },
    getOrder (state) {
      return state.order;
    },
    getSchools (state) {
      return state.schools;
    },
    getSelectedSchool (state) {
      return state.selectedSchool;
    },
    getExistingSchool (state) {
      return state.existingSchool;
    },
    getStep (state) {
      return state.step;
    },
    getStripeCardToken (state) {
      return state.stripeCardToken;
    },
    getUserOrigin (state) {
      return state.userOrigin;
    }
  }
};

export default studentStore;
