import axios from 'axios';

const healthcheck = {
  namespaced: true,
  state: {
    count: null,
    organizations: []
  },
  mutations: {
    setOrganizationCount(state, count) {
      state.count = count;
    },
    setOrganizations(state, organizations) {
      organizations.sort((a, b) => b.status - a.status);
      state.organizations = organizations;
    }
  },
  actions: {
    /**
     * Load the software list from the api, and commit the result
     * using the setAll mutation
     * @param {obj}
     * @returns Promise
     */
    loadAccounts({ state, commit }) {
      return new Promise((resolve, reject) => {
        axios.get(process.env.SPENV.APP_URL + '/api/v2/health-check', {withCredentials: true}).then(
          (response) => {
            commit('setOrganizationCount', response.data.count);
            commit('setOrganizations', response.data.organizations);
            resolve(response);
          })
          .catch(
            (error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    /**
     * Load the software list from the api, and commit the result
     * using the setAll mutation
     * @param {obj}
     * @returns Promise
     * @param postData
     */
    dismissUntil({ state, commit }, postData) {
      return new Promise((resolve, reject) => {
        axios.post(process.env.SPENV.APP_URL + '/api/v2/organizations/health', postData, { withCredentials: true }).then(
          (response) => {
            resolve(response);
          })
          .catch(
            (error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    /**
     * Load the software list from the api, and commit the result
     * using the setAll mutation
     * @param {obj}
     * @returns Promise
     * @param patchData
     */
    makeActive({ state, commit }, patchData) {
      return new Promise((resolve, reject) => {
        axios.patch(process.env.SPENV.APP_URL + '/api/v2/organizations/health', patchData, { withCredentials: true }).then(
          (response) => {
            resolve(response);
          })
          .catch(
            (error) => {
            console.log(error);
            reject(error);
          });
      });
    }
  },
  getters: {
    getOrganizationCount(state) {
      return state.count;
    },
    getOrganizations(state) {
      return state.organizations;
    }
  }
};
export default healthcheck;
