<template>
  <footer class="footer py-5 px-3">
    <div class="row">
      <div class="col-md-3 mb-4">
        <ul>
          <a :href="'/'"><b-img :src="SPENV.S3_URL + '/images/logos/sp-logo-white.svg'" class="c-logo mb-3" alt="SolidProfessor"
                                title="SolidProfessor" /></a>
          <li>
            <a :href="'/terms-and-conditions/'" class="" role="button"
               aria-pressed="true" data-toggle="">Terms of Service</a>
          </li>
          <li>
            <a :href="'/privacy/'" class="" role="button"
               aria-pressed="true" data-toggle="">Privacy Policy</a>
          </li>
          <li>© {{ year }} SolidProfessor</li>
        </ul>
      </div>
      <div class="col-md-3 mb-4">
        <ul>
          <li>
            <a :href="'/about-us/'" class="no-decoration"><h4>Company</h4></a>
          </li>
          <li>
            <a :href="'/about-us/'" class="" role="button"
               aria-pressed="true" data-toggle="">Meet Our Team</a>
          </li>
          <li>
            <a :href="'/author/'" class="" role="button"
               aria-pressed="true" data-toggle="">Meet Our Authors</a>
          </li>
          <li>
            <a :href="SPENV.STUDENT_STORE_URL" class="" role="button"
               aria-pressed="true" data-toggle="">Student Store</a>
          </li>
        </ul>
      </div>
      <div class="col-md-3 mb-4">
        <ul>
          <li>
            <a :href="'/blog/'" class="no-decoration"><h4>Blog</h4></a>
          </li>
          <li>
            <a :href="'/blog/category/course-releases/'" class="" role="button"
               aria-pressed="true" data-toggle="">Course Releases</a>
          </li>
          <li>
            <a :href="'/blog/category/news-updates/'" class="" role="button"
               aria-pressed="true" data-toggle="">News &amp; Updates</a>
          </li>
          <li>
            <a :href="'/blog/category/stories/'" class="" role="button"
               aria-pressed="true" data-toggle="">Success Stories</a>
          </li>
        </ul>
      </div>
      <div class="col-md-3 mb-4">
        <ul>
          <li>
            <a :href="'/contact-us/'" class="no-decoration"><h4>Contact Us</h4></a>
          </li>
          <li>
            <a :href="'/support/'" class="" role="button"
               aria-pressed="true" data-toggle="">Support</a>
          </li>
          <li>
            <a :href="'/contact-us/'" class="" role="button"
               aria-pressed="true" data-toggle="">Email Us</a>
          </li>
          <li>
            <a href="tel:6192698684" class="" role="button"
               aria-pressed="true" data-toggle="">+1 (619) 269-8684</a>
          </li>
          <a href="https://www.facebook.com/solidpro/"><b-img-lazy :src="SPENV.S3_URL + '/images/footer-icons/FacebookIcon-SolidProfessor.png'" alt="Follow SolidProfessor on Facebook" /></a>
          <a href="https://twitter.com/SolidProfessor"><b-img-lazy :src="SPENV.S3_URL + '/images/footer-icons/TwitterIcon-SolidProfessor.png'" alt="Follow SolidProfessor on Twitter" /></a>
          <a href="https://www.linkedin.com/company/1423544?trk=tyah&amp;trkInfo=clickedVertical%3Acompany%2CclickedEntityId%3A1423544%2Cidx%3A2-1-2%2CtarId%3A1469480854171%2Ctas%3Asolidprofessor"><b-img-lazy :src="SPENV.S3_URL + '/images/footer-icons/LinkedInIcon-SolidProfessor.png'" alt="Subscribe to SolidProfessor on LinkedIn" /></a>
          <a href="https://www.instagram.com/solidprofessor/"><b-img-lazy style="padding-left: 7px;" :src="SPENV.S3_URL + '/images/footer-icons/instagram-icon-w.png'" alt="Follow SolidProfessor on Instagram" /></a>
          <a href="https://www.youtube.com/user/SolidProfessor"><b-img-lazy style="padding-left: 10px;" :src="SPENV.S3_URL + '/images/footer-icons/youtube-icon-w.png'" alt="Subscribe to SolidProfessor on Youtube" /></a>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    year () {
      return new Date().getFullYear();
    }
  }
};
</script>

<style scoped>
</style>
