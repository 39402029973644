<template>
  <b-alert class="alert-fixed mt-3 col-sm-8 offset-sm-2" :variant="variant" dismissible
           fade :show="show" @dismissed="dismiss">
    <slot />
  </b-alert>
</template>

<script>
export default {
  name: 'FixedAlert',
  // 2-way binding: https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model
  model: {
    prop: 'show',
    event: 'update'
  },
  props: {
    show: Boolean,
    variant: {
      type: String,
      default: 'danger'
    }
  },
  methods: {
    dismiss() {
      this.$emit('update', false);
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
