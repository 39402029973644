import analytics from '@/../../shared/js/analytics.js';

const analyticsClickHandler = {
  methods: {
    analyticsClickEvent: function(category = '', action = '', label = '', value = 1) {
      analytics.sendCustomEvent(category, action, label, value);
    },
    analyticsSetUser: function(user) {
      analytics.setUser(user);
    }
  }
};

export default analyticsClickHandler;
