<template>
  <div id="baselayout" class="d-flex flex-column">
    <app-emulation />
    <app-header class="fixed-top" />
    <div class="h-auto align-items-stretch flex-grow-1">
      <slot />
    </div>
    <app-footer class="py-5 px-3" />
  </div>
</template>
<script>
import Emulation from '@/components/Emulation.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'Base',
  components: {
    'app-emulation': Emulation,
    'app-header': Header,
    'app-footer': Footer
  }
};
</script>

<style lang="scss">
#baselayout {
  height: 100%;
  min-height: 100vh;
}
</style>
