import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import routes from '../routes';

Vue.use(VueRouter);
Vue.use(Meta);

const router = new VueRouter({
  mode: 'history',
  routes: routes.all,
  scrollBehavior(to, from, savedPosition) {
    // For navigation exclusively within the course page, maintain current x,y. Otherwise, navigation from a new route scrolls the page to top
    if ('swslug' in to.params && 'swslug' in from.params && ('courseslug' in to.params && 'courseslug' in from.params)) {
      if (from.params['swslug'] === to.params['swslug'] && from.params['courseslug'] === to.params['courseslug']) {
        return;
      }
    }
    return { x: 0, y: 0 };
  }
});

/* Custom Titles based on route metas sr 4/11/18 */
router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    if (typeof to.meta.title === 'function') {
      document.title = to.meta.title(to);
    } else {
      document.title = to.meta.title;
    }
  }
  // If user needs to login with PLTW redirect to login
  if (_.includes(['pltw', 'pltwokta'], to.query.auth) &&  to.name != 'Login') {
    let pltw_return_url = [process.env.SPENV.WWW_URL, to.path].join('');
    next({name: 'Login', query: { auth: to.query.auth, return_url: pltw_return_url }});
  }
  next();
});


export default router;
