const Library = () => import('@/components/Library/Library');
const Software = () => import('@/components/Library/Software');
const Course = () => import('@/components/Library/Course');
const Test = () => import('@/components/Library/TestPage');

export default [
  {
    path: '/' + process.env.SPENV.LIBRARY_FOLDER_DISPLAY,
    name: 'library',
    component: Library,
    meta: {
      layout: 'Base',
    },
  },
  {
    path: '/' + process.env.SPENV.LIBRARY_FOLDER_DISPLAY + '/test',
    name: 'test',
    component: Test,
    meta: {
      layout: 'Base',
    },
  },
  {
    path: '/' + process.env.SPENV.LIBRARY_FOLDER_DISPLAY + '/:swslug',
    name: 'software',
    component: Software,
    meta: {
      layout: 'Base',
    },
  },
  {
    // lessonid is optional https://github.com/vuejs/vue-router/issues/235#issuecomment-245447122 jhamm 5/7/18
    path: '/' + process.env.SPENV.LIBRARY_FOLDER_DISPLAY + '/:swslug/:courseslug/:lessonid?',
    name: 'course',
    component: Course,
    meta: {
      layout: 'Base',
    },
  },
];
