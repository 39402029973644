<template>
  <div id="SkillsgapLayout" class="d-flex flex-column">
    <app-emulation />
    <app-header class="fixed-top" />
    <div class="h-auto align-items-stretch flex-grow-1">
      <slot />
    </div>
    <app-footer class="py-5 px-3" />
  </div>
</template>
<script>
import Emulation from '@/components/Emulation.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'SKillsgapLayout',
  components: {
    'app-emulation': Emulation,
    'app-header': Header,
    'app-footer': Footer
  }
};
</script>

<style lang="scss">
#SkillsgapLayout {
  height: 100%;
  min-height: 100vh;
  :focus{
    outline-width: 0;
  }

  h1 {
    font-family: Open Sans,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 64px;
  }
  h2 {
    font-family: Open Sans,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
  }
  h3 {
    font-family: Open Sans,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
  }
  h5 {
    font-family: Open Sans,sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }
  p {
    font-family: Open Sans,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  .blue-link {
    color: #00295B;
  }
  .pointer {
    cursor: pointer;
  }
  .purple {
    color: #653AC9;
  }
  .sp-green {
    color: $sp-green;
  }
  .white {
    color: #FFFFFF;
  }
}
body.sub-nav-padding {
  #SkillsgapLayout {
    padding-top: 36px !important;
  }
}
</style>
