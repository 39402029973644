import axios from 'axios/index';

export default {
  buildQuery(query, courseIds, searchWeights, facetFilters = null) {
    let filteredIds = courseIds.filter(number => number >= 400);
    let q = query;
    let qs = '';
    qs += '(';
    qs += '(' + 'course_id:(' + filteredIds.toString().replace(/,/g, ' OR ') + ')' + ')';
    qs += ')';
    if (facetFilters) {
      qs += facetFilters;
    }
    qs += ' AND (';
    qs += 'name:(' + q + ')^' + searchWeights.name;
    qs += ' OR description:(' + q + ')^' + searchWeights.description;
    qs += ' OR course:(' + q + ')^' + searchWeights.course;
    qs += ' OR section:(' + q + ')^' + searchWeights.section;
    qs += ' OR lesson_script:(' + q + ')^' + searchWeights.lesson_script;
    qs += ')';
    return qs;
  },
  /**
   * Save Search keyword to db
   * @param {obj}
   * @returns Promise
   */
  saveSearchKeyword (searchKeyword) {
    return new Promise((resolve, reject) => {
      let postData = {
        'query': searchKeyword
      };
      axios.post(process.env.SPENV.APP_URL + '/api/v1/app/user/search', postData, {
        withCredentials: true,
        validateStatus: function (status) {
          return status < 500; // Reject only if the status code is greater than or equal to 500
        }
      })
        .then(response => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }  
};
