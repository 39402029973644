import axios from 'axios';

const plans = {
  namespaced: true,
  state: {
    plans: null,
    selectedPlan: null,
    notSelected: true
  },
  mutations: {
    /**
     * Setter for plans list
     * @param state
     * @param plans
     */
    setPlans(state, plans) {
      state.plans = plans;
    },
    setSelectedPlan(state, selection) {
      state.selectedPlan = selection;
      state.notSelected = false;
    }
  },
  actions: {
    /**
     * Get SAML Provider ID (not a secret) from our API based on provider name
     * @param state
     * @param providerName
     * @returns {Promise}
     */
    getSAMLProvider({state}, providerName) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.SPENV.LARAVEL_API}get-saml-provider-by-domain/${providerName}`, { withCredentials: true }).then(
          (response) => {
            resolve(response);
          })
          .catch(
            (error) => {
              reject(error);
            });
      });
    },
    /**
     * Load plans list from the api, and commit the result
     * using the set mutation
     * @param object
     * @returns Promise
     */
    loadPlans({ state, commit }) {
      return new Promise((resolve, reject) => {
        axios.get(process.env.SPENV.APP_URL + '/api/v2/plans').then(
          (response) => {
            commit('setPlans', response.data);
            resolve();
          })
          .catch(
            (error) => {
            reject(error);
          });
      });
    },
    selectPlan(plan) {
      plans.commit('setSelectedPlan', plan);
    }
  },
  getters: {
    getPlans(state) {
      return state.plans;
    },
    getSelectedPlan(state) {
      return state.selectedPlan;
    },
    getNotSelected(state) {
      return state.notSelected;
    },
    getPlanByID: state => id => {
      return state.plans.find(plan => plan.id === parseInt(id));
    }
  }
};

export default plans;
