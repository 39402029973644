import axios from 'axios';
import analytics from '@/../../shared/js/analytics.js';

const user = {
  namespaced: true,
  state: {
    user: null,
    isLTIUser: false, // (boolean) indicating launch originating from LMS/LTI
    password: null,
    passwordConfirm: null,
    existingUserAccount: null, // a SP `customer` object associated with a specific email PRIOR TO MAKING A NEW ACCOUNT
    stripeUser: null,
    SPENV: process.env.SPENV,
  },
  mutations: {
    /**
     * Setter for user object
     * @param state
     * @param user
     */
    setUser (state, user) {
      state.user = user;
    },
    setEmail (state, email) {
      state.user.email = email;
    },
    setPassword (state, password) {
      state.password = password;
    },
    setPasswordConfirm (state, password) {
      state.passwordConfirm = password;
    },
    setIsLTIUser (state, bool) {
      state.isLTIUser = bool;
    },
    setStripeUser (state, data) {
      state.stripeUser = data;
    },
    setExistingUserAccount (state, user) {
      state.existingUserAccount = user;
    },
    /**
     * Setter for toggling user autoplay jhamm 5/17/18
     * @param {object} state
     * @param val
     */
    setAutoplay (state, val) {
      state.user.settings.autoplay = val;
    },
    /**
     * Setter for toggling user video type jhamm 5/22/18
     * @param {object} state
     * @param val
     */
    setVideoType (state, val) {
      state.user.settings.video_type = val;
    },
    /**
     * Setter for setting video playback rate jhamm 5/22/18
     * @param {object} state
     * @param rate
     */
    setVideoPlaybackRate (state, rate) {
      state.user.settings.player_speed = rate;
    },
  },
  actions: {
    /**
     * Load user info from the api, and commit the result
     * using the set mutation
     * @param object
     * @returns Promise
     */
    loadUserInfo({state, commit, dispatch}) {
      return new Promise((resolve, reject) => {
        axios.get(state.SPENV.APP_URL + '/api/v2/user', {withCredentials: true}).then(
          (response) => {
            commit('setUser', response.data);
            analytics.setUser(response.data, state.SPENV.CHURNZERO_API_KEY);
            resolve(response.data);
          })
          .catch(
            (error) => {
              reject(error);
            });
      });
    },
    /**
     * Update a customer record
     * @param state
     * @param commit
     * @param dispatch
     * @param postData -- must contain an associative array with 'email' and
     *  'password' keys
     * @returns {Promise<unknown>}
     */
    updateUserInfo({state, commit, dispatch}, postData) {
      return new Promise((resolve, reject) => {
        axios.patch(state.SPENV.ACCOUNTS_API_URL + '/user', postData,{withCredentials: true}).then(
          (response) => {
            dispatch('loadUserInfo');
            resolve();
          })
          .catch(
            (error) => {
              reject(error);
            });
      });
    },

    /**
     * Toggle autoplay for user jhamm 5/17/18
     * @param {obj}
     * @returns Promise
     * @param autoplay
     */
    updateAutoplaySetting({state, commit}, autoplay) {
      return new Promise((resolve, reject) => {
        let postData = {
          setting: 'autoplay',
          value: autoplay,
        };
        axios.post(process.env.SPENV.APP_URL + '/api/v2/user', postData, {withCredentials: true}).then(
          (response) => {
            commit('setAutoplay', autoplay);
            resolve();
          })
          .catch(
            (error) => {
              reject(error);
            });
      });
    },
    /**
     * Toggle video type (html5, flash) for user jhamm 5/22/18
     * @param {obj}
     * @returns Promise
     * @param videoType
     */
    setVideoType({state, commit}, videoType) {
      return new Promise((resolve, reject) => {
        let postData = {
          setting: 'render_mode',
          value: videoType,
        };
        axios.post(process.env.SPENV.APP_URL + '/api/v2/user', postData, {withCredentials: true}).then(
          (response) => {
            resolve();
          })
          .catch(
            (error) => {
              reject(error);
            });
      });
    },
    /**
     * Toggle video type (html5, flash) for user jhamm 5/22/18
     * @param {obj}
     * @returns Promise
     * @param rate
     */
    updateVideoPlaybackRate({state, commit}, rate) {
      return new Promise((resolve, reject) => {
          // If the rate is under the minimum speed of .75 set to minimum
          if (parseFloat(rate) < 0.75) {
            rate = 0.75;
          }
          let postData = {
          setting: 'player_speed',
          value: rate,
        };

        axios.post(process.env.SPENV.APP_URL + '/api/v2/user', postData, {withCredentials: true}).then(
          (response) => {
            commit('setVideoPlaybackRate', rate);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    validateUserStore: ({state, commit}) => {
      if (state.user && state.user.id){
        return true;
      } else {
        commit('setUser', null);
        return false;
      }
    },
  },
  getters: {
    getUser: (state) => {
      return state.user;
    },
    getEmail: (state) => {
      return state.user && state.user.email ? state.user.email : null;
    },
    getPassword: (state) => {
      return state.password;
    },
    getPasswordConfirm: (state) => {
      return state.passwordConfirm;
    },
    getIsLTIUser: (state) => {
      return state.isLTIUser;
    },
    getStripeUser (state) {
      return state.stripeUser;
    },
    getExistingUserAccount: (state) => {
      return state.existingUserAccount;
    },
    getUserSettingVideoAutoplay: (state) => {
      let autoplay = true;
      if (state.user.settings.autoplay == 0) {
        autoplay = false;
      }
      return autoplay;
    },
    getUserSettingVideoPlaybackRate: (state) => {
      let rate = 1.00;
      if (_.has(state, 'user.settings.player_speed') && state.user.settings.player_speed < 0.75) {
        rate = state.user.settings.player_speed;
      }
      return rate;
    },
    isMember: (state) => {
      if (state.user) {
        if (state.user.membership) {
          if (!state.user.membership.expired_at || new Date(state.user.membership.expired_at) > new Date()) {
            return true;
          }
        }
      }
      return false;
    },
    validateUserStore: (state, getters) => {
      if (getters.getUser && getters.getUser.id){
        return true;
      } else {
        return false;
      }
    },
    isLoggedin: (state, getters) => {
      if (getters.getUser && getters.getUser.is_logged_in) {
        return getters.getUser.is_logged_in;
      } else {
        return false;
      }
    },
    isOrgAdmin: (state, getters) => {
      if (getters.getUser && getters.getUser.organization && getters.getUser.organization.role) {
        return (getters.getUser.organization.role  === 'admin') || getters.getUser.organization.role  === 'master_admin';
      } else {
        return false;
      }
    },
    isUserEmulation: (state, getters) => {
      if (getters.getUser && getters.getUser.is_emulation_mode) {
        return state.user.is_emulation_mode;
      } else {
        return false;
      }
    },
    isAffiliate: (state, getters) => {
      if (getters.getUser && getters.getUser.affiliate) {
        return state.user.affiliate;
      } else {
        return false;
      }
    },
    isLTIUser: (state, getters) => {
      if (getters.getUser && getters.getUser.isLTIUser) {
        return getters.getUser.isLTIUser;
      } else {
        return false;
      }
    },
    isEmployee: (state, getters) => {
      if (getters.getUser && getters.getUser.employee) {
        return getters.getUser.employee;
      } else {
        return false;
      }
    },
    isEnterprise: (state, getters) => {
      if (getters.getUser && _.has(getters.getUser, 'organization.type') && getters.getUser.organization.type === 'enterprise') {
        return true;
      } else {
        return false;
      }
    },
    isAcademic: (state, getters) => {
      if (getters.getUser && _.has(getters.getUser, 'organization.type') && getters.getUser.organization.type === 'academic') {
        return true;
      } else {
        return false;
      }
    },
  }
};

export default user;
