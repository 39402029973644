<template>
  <div>
    <admin-header />
    <slot />
  </div>
</template>
<script>
import AdminHeader from '@/components/Admin/AdminHeader';
import bodyClass from '@/utils/mixins/BodyClass';

export default {
  name: 'AdminLayout',
  components: {
    'admin-header': AdminHeader
  },
  mixins: [bodyClass],
  data() {
    return {
      bodyClass: 'body-offwhite'
    };
  }
};
</script>

<style lang="scss"></style>
