<template>
  <b-navbar toggleable="lg" variant="primary" type="dark"
            class="fixed-top">
    <b-navbar-brand :href="SPENV.APP_URL + '/lmsapp/dashboard'">
      <img :src="SPENV.S3_URL + '/images/logos/sp-logo-white.svg'" class="c-logo" alt="SolidProfessor">
    </b-navbar-brand>
    <b-navbar-nav>
      <b-nav-item :href="SPENV.ADMIN_PANEL_URL + '/backend/new'">
        Go back to SP Admin Panel
      </b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
export default {
  name: 'AdminHeader',
};
</script>
