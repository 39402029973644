import store from '@/store';

const UserPlugin = {
  install(Vue, options) {
    Vue.mixin({
      data() {
        return {
          SPENV: process.env.SPENV
        };
      },
      computed: {
        user: {
          get: function() {
            return store.getters['user/getUser'];
          },
          set: function(newValue) {
            store.commit('user/setUser', newValue);
          },
        },
        email: {
          get: function () {
            return this.$store.getters['user/getEmail'];
          },
          set: function (email) {
            this.$store.commit('user/setEmail', email);
          }
        },
        password: {
          get: function () {
            return this.$store.getters['user/getPassword'];
          },
          set: function (password) {
            this.$store.commit('user/setPassword', password);
          }
        },
        confirmPassword: {
          get: function () {
            return this.$store.getters['user/getPasswordConfirm'];
          },
          set: function (password) {
            this.$store.commit('user/setPasswordConfirm', password);
          }
        },
        existingUserAccount: {
          get: function () {
            return this.$store.getters['user/getExistingUserAccount'];
          },
          set: function (isLTIUser) {
            this.$store.commit('user/setExistingUserAccount', isLTIUser);
          }
        },
        isLoggedIn() {
          return store.getters['user/isLoggedin'];
        },
        isOrgAdmin() {
          return store.getters['user/isOrgAdmin'];
        },
        isUserEmulation() {
          return store.getters['user/isUserEmulation'];
        },
        isAffiliate() {
          return store.getters['user/isAffiliate'];
        },
        isEnterprise() {
          return store.getters['user/isEnterprise'];
        },
        isAcademic() {
          return store.getters['user/isAcademic'];
        },
        isLTIUser: {
          get: function () {
            return this.$store.getters['user/getIsLTIUser'];
          },
          set: function (isLTIUser) {
            this.$store.commit('user/setIsLTIUser', isLTIUser);
          }
        },
        isEmployee() {
          return store.getters['user/isEmployee'];
        },
        isMember() {
          return store.getters['user/isMember'];
        },
        stripeUser: {
          get: function () {
            return this.$store.getters['user/getStripeUser'];
          },
          set: function (stripeUser) {
            this.$store.commit('user/setStripeUser', stripeUser);
          }
        }
      },
      methods: {
        refreshUserData(){
          return new Promise((resolve, reject) => {
            this.$store.dispatch('user/loadUserInfo').then(
              (res) => {
                resolve();
              }
            ).catch(error => {
                console.log(error);
                reject(error);
              });
          });
        }
      },
    });
  }
};
export default UserPlugin;
