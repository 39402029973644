import Vue from 'vue';

Vue.filter('formatCurrency', function(value) {
  return '$' + value.toFixed(2);
});


Vue.filter('striphtml', function (value) {
  if (typeof value === 'string' ) {
    value = value.replace(/(<([^>]+)>)/ig,'');
  }
  return  value;
});
