const ClassesBase = () => import('@/components/Classes/ClassesBase');
const LessonPlanCreateBase = () => import('@/components/Classes/CreateLessonPlanBase');
const LessonPlanCreatePath = () => import('@/components/Classes/LessonPlanType');
const LessonPlanName = () => import('@/components/Classes/LessonPlanName');
const LessonPlanSubject = () => import('@/components/Classes/LessonPlanSubject');
const LessonPlanSelect = () => import( '@/components/Classes/LessonPlanSelect');
const LessonPlanPreview = () => import('@/components/Classes/LessonPlanPreview');
const ClassesAdmin = () => import('@/components/Classes/ClassesAdminBase');
const ClassesUsersAdmin = () => import( '@/components/Classes/ClassesUsersAdmin');

let defaultLayout = 'ClassesLayout';
//TODO: FIX Named routes

export default [
  {
    path: '/classes',
    name: 'classes',
    alias: '/groups',
    component: ClassesBase,
    redirect: { name: 'ClassesAdmin' },
    meta: {
      layout: defaultLayout
    },
    children: [
      {
        // this will preselect a multigroup and redirect the admin to /:id/users
        path: 'admin',
        name: 'ClassesAdmin',
        component: ClassesAdmin,
        meta: {
          layout: defaultLayout
        }
      },
      {
        path: ':multigroup_id/users',
        name: 'ClassesUsersAdmin',
        component: ClassesUsersAdmin,
        meta: {
          layout: defaultLayout
        }
      },
      {
        path: 'create',
        redirect: { name: 'CreateLessonType' },
        name: 'create',
        component: LessonPlanCreateBase,
        meta: {
          layout: defaultLayout
        },
        children: [
          {
            path: 'path',
            name: 'CreateLessonType',
            component: LessonPlanCreatePath,
            meta: {
              layout: defaultLayout
            }
          },
          {
            path: 'name',
            name: 'LessonPlanName',
            component: LessonPlanName,
            meta: {
              layout: defaultLayout
            }
          },
          {
            path: 'subject',
            name: 'LessonPlanSubject',
            component: LessonPlanSubject,
            meta: {
              layout: defaultLayout
            }
          },
          {
            path: 'plan',
            name: 'LessonPlanSelect',
            component: LessonPlanSelect,
            meta: {
              layout: defaultLayout
            }
          },
          {
            path: 'preview',
            name: 'LessonPlanPreview',
            component: LessonPlanPreview,
            meta: {
              layout: defaultLayout
            }
          }
        ]
      },
      {
        path: '*',
        redirect: { name: 'CreateLessonType' },
        meta: {
          layout: defaultLayout
        }
      }
    ]
  }
];
