<template>
  <div class="accounts">
    <header class="navbar navbar-light bg-white fixed-top">
      <a class="navbar-brand" :href="SPENV.WWW_URL">
        <b-img-lazy :src="SPENV.S3_URL + '/images/logos/sp-logo.png'" class="c-logo " alt="SolidProfessor logo" />
      </a>
      <button
        class="btn btn-primary d-sm-none d-md-none d-lg-none d-xl-none"
        type="button"
        @click="collapseOpen = !collapseOpen">
        <font-awesome-icon icon="bars" />
      </button>
      <b-collapse id="collapse" v-model="collapseOpen" class="navbar-collapse d-sm-none d-md-none d-lg-none d-xl-none">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item" :class="{ active: isRoute('SignupSelectPlan') }">
            <router-link
              :to="{ name: 'SignupSelectPlan' }"
              class="nav-link"
              href="javascript;">
              Select a Membership<span class="sr-only">(current)</span>
            </router-link>
          </li>
          <li class="nav-item" :class="{ active: isRoute('SignupAccountInfo') }">
            <router-link
              :to="{ name: 'SignupAccountInfo' }"
              class="nav-link"
              :class="{ 'disabled font-weight-normal': planNotSelected }"
              href="javascript;">
              Membership Information
            </router-link>
          </li>
          <li class="nav-item" :class="{ active: isRoute('SignupPayment') }">
            <a href="javascript:void(0)" class="nav-link"
               :class="{'disabled font-weight-normal': planNotSelected || !isLoggedIn}" @click="goToPayment()">Payment
              Information</a>
          </li>
          <li
            v-if="isLoggedIn && user && user.membership && user.membership.plan_id"
            class="nav-item btn btn-outline-primary"
            :class="{ active: isRoute('Login') }">
            <a class="nav-link" href="javascript:void(0);" @click="routeToApp()">Continue Learning</a>
          </li>
          <li
            v-else-if="!isLoggedIn"
            class="nav-item btn btn-outline-primary"
            :class="{ active: isRoute('Login') }">
            <router-link
              :to="{ name: 'Login' }"
              class="nav-link"
              href="javascript:void(0);">
              Already a Member? Login
            </router-link>
          </li>
        </ul>
      </b-collapse>
      <button
        v-if="isLoggedIn && user && user.membership && user.membership.plan_id"
        type="button"
        class="btn btn-primary d-none d-sm-block float-right"
        @click="routeToApp()">
        Continue Learning
      </button>
      <router-link
        v-else-if="!isLoggedIn"
        :to="{ name: 'Login' }"
        class="d-none d-sm-block">
        <button type="button" class="btn btn-primary d-sm-block float-right">
          Already a Member? Login
        </button>
      </router-link>
    </header>

    <div class="container mt-5 d-none d-sm-block">
      <b-nav class="nav-tabs-material border-bottom">
        <b-nav-item
          :to="{ name: 'SignupSelectPlan' }"
          :class="{ active: isRoute('SignupSelectPlan') }"
          active-class="active">
          Select a Membership
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'SignupAccountInfo' }"
          :class="{ active: isRoute('SignupAccountInfo') }"
          :disabled="planNotSelected"
          active-class="active">
          Membership Information
        </b-nav-item>
        <b-nav-item
          :to="{ name: 'SignupPayment', params: { plan_id: selectedPlanID } }"
          :class="{ active: isRoute('SignupPayment') }"
          :disabled="planNotSelected || !isLoggedIn"
          active-class="active"
          @click="goToPayment()">
          Payment Information
        </b-nav-item>
      </b-nav>
    </div>
    <transition :name="transitionName" mode="out-in">
      <slot />
    </transition>
  </div>
</template>

<script>
import redirectMixin from '@/utils/mixins/Redirect';


export default {
  name: 'SignupLayout',
  mixins: [redirectMixin],
  data() {
    return {
      collapseOpen: false,
      transitionName: '',
    };
  },
  computed: {
    selectedPlanID () {
      let value = false;
      let selectedPlan = this.$store.getters['plans/getSelectedPlan'];
      if (selectedPlan) {
        value = selectedPlan.id;
      }
      return value;
    },
    isRoute () {
      return function(name) {
        return name === this.$route.name;
      };
    },
    planNotSelected () {
      let plan = this.$store.getters['plans/getNotSelected'];
      return plan;
    },
    plans () {
      return this.$store.getters['plans/getPlans'];
    },
  },
  created () {
    this.init();
  },
  methods: {
    init () {
      // Get user
      if (!this.user) {
        this.loadUser();
      }
      // Get plans
      if (!this.$store.getters['plan/getPlans']) {
        this.loadPlans();
      }
      this.setTransition();
    },
    loadUser () {
      this.$store.dispatch('user/loadUserInfo')
        .then(
          response => {
            if (this.isLoggedIn) {
              if (this.user.organization || (this.user.affiliate && this.user.affiliate.disableBilling)) {
                window.location.replace(this.SPENV.APP_URL);
              }
            }
          }
        )
        .catch(
          error => {
            // kick to error state
            console.log(error);
          }
        );
    },
    loadPlans () {
      this.$store.dispatch('plans/loadPlans')
        .then(
          response => {
            // if plan id is in url param, set plan to move to step 3 EJL032118
            this.$nextTick(() => this.planFromUrl());
          }
        )
        .catch(
          error => {
            // kick to error state
            console.log(error);
          }
        );
    },
    setTransition () {
      this.$router.beforeEach((to, from, next) => {
        if (to.meta && from.meta) {
          let transitionName = to.meta.transitionName || from.meta.transitionName;
          if (transitionName === 'slide') {
            transitionName = to.meta.transitionOrder < from.meta.transitionOrder ? 'slide-right' : 'slide-left';
          }
          this.transitionName = transitionName || 'fade';
        }
        next();
      });
    },
    routeToApp () {
      this.redirectUrl(this.SPENV.APP_URL);
    },
    planFromUrl () {
      // grabs plan ID from url param, sets plan in state store
      if (this.$route.params.plan_id) {
        let selectedPlan = this.$store.getters['plans/getPlanByID'](
          this.$route.params.plan_id,
        );
        if (selectedPlan === undefined) {
          this.$router.push({name: 'SignupSelectPlan'}).catch(err => {});
        }
        this.$store.commit('plans/setSelectedPlan', selectedPlan);
      }
    },
    goToPayment () {
      let plan = this.$store.getters['plans/getSelectedPlan'];
      if (this.$store.getters['plans/getSelectedPlan']) {
        this.$router.push({
          name: 'SignupPayment',
          params: {plan_id: plan.id},
        }).catch(err => {});
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .sub-nav-padding {
    padding-top: 100px !important;
  }

  .nav-tabs-material {
    .nav-link {
      font-weight: 400 !important;
    }
  }
</style>
