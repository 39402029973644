const params = {
  namespaced: true,
  state: {
    affkey: null,
    gclid: null,
    return_url: null,
    campaign_id: null,
    sourceform: null
  },
  mutations: {
    setAffkey(state, affkey) {
      state.affkey = affkey;
    },
    setGclid(state, gclid) {
      state.gclid = gclid;
    },
    setReturnURL(state, returnUrl) {
      state.return_url = returnUrl;
    },
    setCampaignID(state, campaignID) {
      state.campaign_id = campaignID;
    },
    setSourceForm(state, sourceform) {
      state.sourcefrom = sourceform;
    }
  },
  getters: {
    getAffkey(state) {
      return state.affkey;
    },
    getGclid(state) {
      return state.gclid;
    },
    getReturnURL(state) {
      return state.return_url;
    },
    getCampaignID(state) {
      return state.campaign_id;
    },
    getSourceForm(state) {
      return state.sourceform;
    }
  }
};

export default params;
