const LearningPathList = () => import('@/components/LearningPaths/LearningPathList');
const LearningPath = () => import('@/components/LearningPaths/LearningPath');

export default [
  {
    name: 'paths',
    path: '/' + process.env.SPENV.LIBRARY_FOLDER_DISPLAY + '/paths',
    component: LearningPathList
  },
  {
    name: 'path',
    path: '/' + process.env.SPENV.LIBRARY_FOLDER_DISPLAY + '/path/:pathslug',
    component: LearningPath
  }
];
