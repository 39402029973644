<template>
  <component :is="currentLayout">
    <router-view />
  </component>
</template>
<script>
import Base from '@/layouts/Base.vue';

export default {
  name: 'LayoutBroker',
  props: {
    layouts: {
      type: Object,
      default: Base
    },
    current: {
      type: String,
      default: 'Base'
    }
  },
  computed: {
    /**
     * Return layout component name for current route
     * @return {string}
     */
    currentLayout() {
      const mylayout = this.layouts[this.current] || Base;
      return mylayout;
    }
  }
};
</script>
