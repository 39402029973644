import axios from 'axios';

const player = {
  namespaced: true,
  state: {
    video: null,
    loadingVideo: false,
  },
  mutations: {
    setVideoPlayer(state, val) {
      state.video = val;
    },
    setPlayerLoadingStatus(state, val) {
      state.loadingVideo = val;
    }
  },
  actions: {


  },
  getters: {
    getVideoPlayer(state) {
      return state.video;
    },
    getPlayerLoadingStatus(state) {
      return state.loadingVideo;
    }
  }
};
export default player;
