import axios from 'axios';

const multigroup = {
  namespaced: true,
  state: {
    organizationUsers: {},
    multigroups: {},
    usersMultigroups: {},
    multigroupsPermissions: {},
    aliasLimit: null,
    aliasCount: null,
    loaded: null,
    showAllUsers: false,
  },
  mutations: {
    setMultigroups(state, data) {
      state.multigroups = data;
    },
    setAliasLimit(state, data) {
      state.aliasLimit = data;
    },
    setAliasCount(state, data) {
      state.aliasCount = data;
    },
    setShowAllUsers (state, data) {
      state.showAllUsers = data;
    },
    setUsersMultigroups(state, data) {
      state.usersMultigroups = data;
    },
    updateUserMultigroups(state, data) {
      for (let i = 0; i < state.usersMultigroups.length; i++) {
        for (let k = 0; k < data.length; k++) {
          if (state.usersMultigroups[i].aliasid === parseInt(data[k].value.aliasid)) {
            state.usersMultigroups.splice(i, 1);
          }
        }
      }
    },
    deleteUserMultigroups(state, data) {
      let aliasList = data.map(obj => {
        return parseInt(obj);
      });
      for (let i = 0; i < aliasList.length; i++) {
        if (state.showAllUsers) {
          // this will remove the users from organizationUsers - only when "All members view"
          for (let j = 0; j < state.organizationUsers.length; j++) {
            if (aliasList[i] === state.organizationUsers[j].aliasid) {
              state.organizationUsers.splice(j, 1);
              state.aliasCount--;
            }
          }
        } else {
          state.usersMultigroups.forEach(function (element, index) {
            if (aliasList[i] === element.aliasid) {
              state.usersMultigroups.splice(index, 1);
            }
          });
        }
      }
    },
    setMultigroupsPermissions(state, data) {
      state.multigroupsPermissions[data['customer_id']] = data;
      // Set select all if user has all permissions
      let selectAll = true;
      state.multigroupsPermissions[data['customer_id']].selectAll = true;
      let values = Object.values(state.multigroupsPermissions[data['customer_id']]);
      for (let i = 0; i < values.length; i++) {
        if (!values[i]) {
          selectAll = false;
          break;
        }
      }
      state.multigroupsPermissions[data['customer_id']].selectAll = selectAll;
    },
    setOrganizationUsers(state, data) {
      state.organizationUsers = data;
    }
  },
  actions: {
    /**
     * Load all Multigroups for a given customer
     * @param {*} param
     * @param {*} customerID
     */
    loadMultigroupData({ state, commit }, customerID) {
      return new Promise((resolve, reject) => {
        axios.get(process.env.SPENV.APP_URL + '/api/v2/multigroups', {withCredentials: true}).then(
          (response) => {
            commit('setMultigroups', response.data);
            if (response.data[0] && response.data[0]['aliasLimit']) {
              commit('setAliasLimit', response.data[0]['aliasLimit']);
            }
            resolve();
          })
          .catch(
            (error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    /**
     * Load all users in a given multigroup
     * @param {*} param
     * @param {*} multigroupID
     */
    loadUsersMultigroup({ state, commit }, multigroupID) {
      return new Promise((resolve, reject) => {
        axios.get(process.env.SPENV.APP_URL + '/api/v2/multigroups/' + multigroupID + '/users', { withCredentials: true }).then(
          (response) => {
            _.forEach(response.data, (user) => {
              if (user.first_name)  user.first_name = _.upperFirst(user.first_name);
              if (user.last_name)  user.last_name = _.upperFirst(user.last_name);
            });
            commit('setUsersMultigroups', response.data);
            resolve();
          })
          .catch(
            (error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    /**
     * Load permissions for customer_id
     * @param {*} param
     * @param {*} data -- list [{'customer_id': 141195, 'multigroup_id': 7000}]
     */
    loadMultigroupPermissions({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        axios.get(process.env.SPENV.APP_URL + '/api/v2/multigroups/' + data['multigroup_id'] + '/user/' + data['customer_id'] + '/permissions',{ withCredentials: true }).then(
          (response) => {
            response.data['loaded'] = true;
            response.data['customer_id'] = data['customer_id'];
            commit('setMultigroupsPermissions', response.data);
            resolve();
          })
          .catch(
            (error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    /**
     * Load all organization users
     * @param {*} param
     * @param {*} data adminid (organization_id)
     */
    loadOrganizationUsers({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        axios.get(process.env.SPENV.APP_URL + '/api/v2/organization/' + data + '/users', { withCredentials: true }).then(
          (response) => {
            _.forEach(response.data, (user) => {
              if (user.first_name) user.first_name = _.upperFirst(user.first_name);
              if (user.last_name) user.last_name = _.upperFirst(user.last_name);
            });
            commit('setOrganizationUsers', Object.values(response.data));
            commit('setAliasCount', Object.keys(response.data).length);
            resolve();
          })
          .catch(
            (error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    /**
     * Add alias to organization
     * @param {object}
     * @returns Promise
     * @param data
     */
    addUserToOrganization({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(process.env.SPENV.APP_URL + '/api/v2/organization/' + data.organization_id + '/class/' + data.multigroup_id + '/adduser', { aliasid: data.alias_id, sendEmail: data.send_email }, { withCredentials: true }).then(
          (response) => {
            resolve();
            // add mg to the list of mgs in the aliasid
            // add user object to mgUsers list
          })
          .catch(
            (error) => {
            reject(error);
            console.log(error);
          });
      });
    },
    /*
     * Remove user from multigroup
     * @param {Object} patch data
     */
    removeUserFromMultigroup({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        axios.patch(process.env.SPENV.APP_URL + '/api/v2/organization/users', data, { withCredentials: true }).then(
            (response) => {
            commit('updateUserMultigroups', data);
            resolve();
          })
          .catch(
            (error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    /**
     * Delete multigroup user
     * @param {Object} patch data
     * @param data
     */
    deleteMultigroupUser({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        axios.delete(process.env.SPENV.APP_URL + '/api/v2/organization/users', {data: data, withCredentials: true}).then(
            (response) => {
            commit('deleteUserMultigroups', data);
            resolve();
          })
          .catch(
            (error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    addMultigroupPermissions({ state, commit }, permissions) {
      return new Promise((resolve, reject) => {
        axios.post(process.env.SPENV.APP_URL + '/api/v2/multigroups/user/permissions', permissions, { withCredentials: true }).then(
            (response) => {
              resolve();
            },
            (error) => {
              console.log(error);
              reject(error);
            }
          );
      });
    },
    deleteMultigroupPermissions({ state, commit }, permissions) {
      return new Promise((resolve, reject) => {
        axios.delete(
            process.env.SPENV.APP_URL + '/api/v2/multigroups/user/permissions', {data: permissions, withCredentials: true}).then(
            (response) => {
              resolve();
            },
            (error) => {
              console.log(error);
              reject(error);
            }
          );
      });
    }
  },
  getters: {
    getMultigroups: function(state) {
      return state.multigroups;
    },
    getAliasLimit: function(state) {
      return state.aliasLimit;
    },
    getAliasCount: function(state) {
      return state.aliasCount;
    },
    getUsersMultigroups: function(state) {
      return state.usersMultigroups;
    },
    getMultigroupsPermissions: function(state) {
      return state.multigroupsPermissions;
    },
    getOrganizationUsers: function(state) {
      return state.organizationUsers;
    },
    getShowAllUsers: function (state) {
      return state.showAllUsers;
    },
  }
};

export default multigroup;
