const AdminInvite = () => import('@/components/Accounts/AdminInvite');
const Login = () => import('@/components/Accounts/Login');
const SAML = () => import('@/components/Accounts/SAML');
const OAuthConfirm = () => import('@/components/Accounts/OAuthConfirm');
const OAuthLink = () => import('@/components/Accounts/OAuthLink');
const OAuthError = () => import('@/components/Accounts/OAuthError');
const Signup = () => import('@/components/Accounts/Signup');
const SelectPlan = () => import('@/components/Accounts/SelectPlan');
const AccountInfo = () => import( '@/components/Accounts/AccountInfo');
const Payment = () => import('@/components/Accounts/Payment');
const Main = () => import('@/components/Accounts/Main');
const StudentStore = () => import('@/components/Accounts/StudentStore/StudentStore');
const SelectSchool = () => import('@/components/Accounts/StudentStore/SelectSchool');
const StudentAccount = () => import( '@/components/Accounts/StudentStore/StudentAccount');
const StudentPayment = () => import('@/components/Accounts/StudentStore/StudentPayment');
const StudentConfirmation = () => import( '@/components/Accounts/StudentStore/StudentConfirmation');

export default [
  {
    path: '/account',
    name: 'Main',
    redirect: { name: 'Login' },
    component: Main,
    meta: {
      layout: 'AccountLayout'
    },
    children: [
      {
        path: 'admin/invite',
        name: 'AdminInvite',
        component: AdminInvite,
        meta: {
          layout: 'AccountLayout'
        },
      },
      {
        path: 'login',
        name: 'Login',
        component: Login,
        meta: {
          layout: 'AccountLayout'
        }
      },
      {
        path: 'login/saml',
        name: 'SAML',
        component: SAML,
        meta: {
          layout: 'AccountLayout'
        },
      },
      {
        path: 'oauth/confirm',
        name: 'OAuthConfirm',
        component: OAuthConfirm,
        meta: {
          layout: 'AccountLayout'
        }
      },
      {
        path: 'oauth/link',
        name: 'OAuthLink',
        component: OAuthLink,
        meta: {
          layout: 'AccountLayout'
        }
      },
      {
        path: 'oauth/error',
        name: 'OAuthError',
        component: OAuthError,
        meta: {
          layout: 'AccountLayout'
        }
      },
      {
        path: 'signup',
        name: 'AccountSignup',
        component: Signup,
        redirect: { name: 'SignupSelectPlan' },
        meta: {
          layout: 'None'
        },
        children: [
          {
            path: '',
            redirect: { name: 'SignupSelectPlan' },
          },
          {
            path: 'plan',
            name: 'SignupSelectPlan',
            component: SelectPlan,
            meta: {
              title: 'Select a Plan',
              transitionName: 'slide',
              transitionOrder: 1,
              layout: 'SignupLayout'
            }
          },
          {
            path: 'account',
            name: 'SignupAccountInfo',
            component: AccountInfo,
            meta: {
              title: 'Create an Account',
              transitionName: 'slide',
              transitionOrder: 2,
              layout: 'SignupLayout'
            },
            beforeEnter: (to, from, next) => {
              next(vm => {
                if (!vm.$store.getters['plans/getSelectedPlan']) {
                  next({ name: 'SignupSelectPlan' });
                }
              });
            }
          },
          {
            path: 'payment/:plan_id',
            name: 'SignupPayment',
            component: Payment,
            meta: {
              title: 'Payment',
              transitionName: 'slide',
              transitionOrder: 3,
              layout: 'SignupLayout'
            },
            beforeEnter: (to, from, next) => {
              next(vm => {

                let checkIsLoggedIn = function() {
                  if (vm.$store.getters['user/isLoggedin']) {
                    next();
                  } else {
                    next({name: 'SignupAccountInfo'});
                  }
                };

                if (!vm.$store.getters['user/getUser']) {
                  vm.$store.dispatch('user/loadUserInfo').then(
                    (response) => {
                      checkIsLoggedIn();
                    },
                    (error) => {
                      next({name: 'SignupAccountInfo'});
                    }
                  );
                } else {
                  checkIsLoggedIn();
                }
              });
            }
          }
        ]
      }
    ]
  },
  {
    path: '/student-store',
    name: 'StudentStore',
    redirect: { name: 'SelectSchool' },
    component: StudentStore,
    meta: {
      layout: 'StudentStoreLayout'
    },
    children: [
      {
        path: 'school',
        name: 'SelectSchool',
        component: SelectSchool,
        meta: {
          title: 'SolidProfessor Student Store',
          transitionName: 'slide',
          transitionOrder: 1,
          layout: 'StudentStoreLayout'
        }
      },
      {
        path: 'lti-account',
        name: 'LTI-StudentAccount',
        component: StudentAccount,
        meta: {
          title: 'SolidProfessor Student Store',
          transitionName: 'slide',
          transitionOrder: 2,
          layout: 'StudentStoreLayout'
        }
      },
      {
        path: 'account',
        name: 'StudentAccount',
        component: StudentAccount,
        meta: {
          title: 'SolidProfessor Student Store',
          transitionName: 'slide',
          transitionOrder: 2,
          layout: 'StudentStoreLayout'
        }
      },
      {
        path: 'lti-payment',
        name: 'LTI-StudentPayment',
        component: StudentPayment,
        meta: {
          title: 'SolidProfessor Student Store',
          transitionName: 'slide',
          transitionOrder: 3,
          layout: 'StudentStoreLayout'
        }
      },
      {
        path: 'payment',
        name: 'StudentPayment',
        component: StudentPayment,
        meta: {
          title: 'SolidProfessor Student Store',
          transitionName: 'slide',
          transitionOrder: 3,
          layout: 'StudentStoreLayout'
        }
      },
      {
        path: 'confirmation',
        name: 'StudentConfirmation',
        component: StudentConfirmation,
        meta: {
          title: 'SolidProfessor Student Store',
          layout: 'StudentStoreLayout'
        }
      }
    ]
  },
];
