import axios from 'axios';
import Vue from 'vue';

/* jhamm 5/2/18
The software state is converted to a flat list in the vuex store.  This is the recommended way of constructing the store
https://forum.vuejs.org/t/vuex-best-practices-for-complex-objects/10143/2
*/

const software = {
  namespaced: true,
  state: {
    courseChanges: null,
    isNew: false,
    learningPaths: [],
    loaded: {
      software_list: false,
      courses: false,
    },
    lockedModalShow: false,
    metaData: {},
    software_list: [],
    selectedLesson: null,
  },
  mutations: {
    setCourseChanges (state, val) {
      state.courseChanges = val;
    },
    setIsNew (state, val) {
      state.isNew = val;
    },
    setLearningPaths (state, val) {
      state.learningPaths = val;
    },
    setLockedModalShow(state, val) {
      state.lockedModalShow = val;
    },
    setSelectedLesson(state, val) {
      state.selectedLesson = val;
    },
    setSoftwareListLoaded(state, val) {
      state.loaded.software_list = val;
    },
    setMetaData(state, val) {
      state.metaData = val;
    },
    /**
     * Setter for software list
     * @param state
     * @param list
     */
    setSoftwareList(state, list) {
      if (state.software_list.length === 0) {
        state.software_list = list;
      } else {
        // might have some software elements in the list currently - merge together
        let newList = list;
        for (let i = 0; i < state.software_list.length; i++) {
          for (let j = 0; j < newList.length; j++) {
            if (state.software_list[i].id === newList[j].id) {
              newList[j] = Object.assign(newList[j], state.software_list[i]);
            }
          }
        }
        state.software_list = newList;
      }
    },
    /**
     * Add / replace a software in the list
     * merge the objects together
     * https://stackoverflow.com/a/41855691/6206295
     * @param {object} state
     * @param {object} val
     */
    // TODO: WTF IS THIS?
    setSoftware(state, val) {
      let software = val.software;
      if (val.isCourseListLoaded) {
        software.isCourseListLoaded = true;
      }
      let isSoftwareInList = false;
      // loop through software list
      for (let a = 0; a < state.software_list.length; a++) {
        // find matching software in software_list
        if (!state.software_list[a].id || state.software_list[a].id === software.id) {
          isSoftwareInList = true;
          // If courses array has not been loaded add course(s) to software jhamm 5/29/18
          if (state.software_list[a].courses === undefined) {
            Vue.set(state.software_list, a, Object.assign(state.software_list[a], software));
            // Courses array found jhamm 5/29/18
          } else {
            // Loop through existing courses array in selected software jhamm 5/29/18
            for (let b = 0; b < software.courses.length; b++) {
              let isCourseInList = false;
              // Loop through courses array for new software/course jhamm 5/29/18
              for (let c = 0; c < state.software_list[a].courses.length; c++) {
                // Course in selected software update with sections/lessons data jhamm 5/29/18
                if (state.software_list[a].courses[c].id === software.courses[b].id) {
                  isCourseInList = true;
                  // If header property not in selected software add it here jhamm 6/12/18
                  if (!state.software_list[a].hasOwnProperty('header')) {
                    state.software_list[a].header = software.header;
                  }
                  // If description property not in selected software add it here jhamm 6/12/18
                  if (!state.software_list[a].hasOwnProperty('description')) {
                    state.software_list[a].description = software.description;
                  }
                  // This was added in the past but got lost during the move from library folder to common
                  Vue.set(state.software_list[a].courses[c], 'sections', software.courses[b].sections);
                  Vue.set(state.software_list[a].courses, c, Object.assign(state.software_list[a].courses[c], software.courses[b]));
                  break;
                }
              }
              if (!isCourseInList) {
                state.software_list[a].courses.push(software.courses[b]);
              }
              // the first time you go from a course to a software the software.course exists but the property isCourseListLoaded doesn't making the pase error
              if (!state.software_list[a].isCourseListLoaded) {
                Vue.set(state.software_list[a], 'isCourseListLoaded', software.isCourseListLoaded);
              }
            }
          }
        }
      }
      // If software not loaded add to software list jhamm 6/11/18
      if (!isSoftwareInList) {
        state.software_list.push(software);
      }
    },
    saveBookmark(state, lesson) {
      lesson.bookmark = true;
    },
    deleteBookmark(state, lesson) {
      lesson.bookmark = false;
    },
    setLessonViewed(state, data) {
      // this will only set the lesson viewed to true if it wasn't true already, as well as incrementing the num_viewed by one JCS 05/21/2018
      if (!data.lesson.viewed) {
        data.lesson.viewed = true;
        let numViewed = data.course.num_viewed;
        data.course.num_viewed = numViewed + 1;
      }
    },
    updateTestScore(state, data) {
      if (data.test_score * 100 > data.course.test_score || !data.course.test_score) {
        data.course.test_score = data.test_score * 100;
      }
    },
    setCertStatus(state, data) {
      // this will set the local course.cert status to what gets received after the test or the video player returns a cert.elegibility jcervantes 05/15/18
      if (data.course.id === data.cert.drmid) {
        if (data.course.cert.status === 'close' && data.course.cert.date_notified && data.cert.status === 'earned') {
          // we need to be able to show the earned notification, we use the same column for close and for earned jcervantes 05/15/18
          data.course.cert.date_notified = null;
        }
        if (data.cert.status === 'earned') {
          // trigger in-player cert notification
          data.course.cert.show_notification = true;
        }
        data.course.cert.status = data.cert.status;
      }
    },
    setCertNotifiedDate(state, course) {
      course.cert.date_notified = Date(Date.UTC());
    },
  },
  actions: {
    /**
     * Get lesson data by lesson id jhamm 5/9/18
     * @param {object}
     * @param {int} lessonid
     * @returns Promise
     */
    findLessonbyId({ state, commit }, lessonid) {
      return new Promise((resolve, reject) => {
        axios.get(process.env.SPENV.APP_URL + '/api/v2/lesson/details/' + lessonid, { withCredentials: true }).then(
          (response) => {
            commit('setSelectedLesson', response.data);
            // endpoint now returns 200 with details to show that the person has no perms for the lesson
            if (response.data.lesson_type == 'unauthorized') {
              commit('setLockedModalShow', true);
            }
            resolve(response.data);
          },
          (error) => {
            commit('setLockedModalShow', true);
            reject(error);
          }
        );
      });
    },
    /**
     * Load the software list from the api, and commit the result
     * using the setAll mutation
     * @param {obj}
     * @returns Promise
     */
    loadSoftwareList({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(process.env.SPENV.APP_URL + '/api/v2/software', {withCredentials: true}).then(
          (response) => {
            commit('setSoftwareList', response.data);
            commit('setSoftwareListLoaded', true);
            resolve();
          },
          (error) => {
            console.log(error);
            reject(error);
          },
        );
      });
    },
    /**
     * sr 4/3/18
     * Find a single software object with course list by slug
     * from the api
     * @param {object}
     * @param {string} slug
     */
    loadSoftwareBySlug({state, commit}, slug) {
      return new Promise((resolve, reject) => {
        axios.get(process.env.SPENV.APP_URL + '/api/v2/software/' + slug, {withCredentials: true}).then(
          (response) => {
            commit('setSoftware', {
              software: response.data,
              isCourseListLoaded: true,
            });
            resolve();
          },
          (error) => {
            console.log(error);
            reject(error);
          },
        );
      });
    },
    /**
     * Find a metadata for a single entity based on params
     * from the api
     * @param {object}
     * @param params
     */
    loadMetaData({state, commit}, params) {
      return new Promise((resolve, reject) => {
        axios.get(process.env.SPENV.APP_URL + '/api/v2/seo/type/' + params.type + '/' + params.slug, {withCredentials: true}).then(
          (response) => {
            commit('setMetaData', response.data[0]);
            resolve();
          },
          (error) => {
            console.log(error);
            reject(error);
          },
        );
      });
    },
    /**
     * Find a metadata for a single entity based on type
     * from the api
     * @param {object}
     * @param params
     */
    loadMetaDataByType({state, commit}, params) {
      return new Promise((resolve, reject) => {
        axios.get(process.env.SPENV.APP_URL + '/api/v2/seo/type/' + params.type, {withCredentials: true}).then(
          (response) => {
            commit('setMetaData', response.data[0]);
            resolve();
          },
          (error) => {
            console.log(error);
            reject(error);
          },
        );
      });
    },
    /**
     * Load the course with sections array and lessons array
     * @param {object}
     * @returns Promise
     * @param slugs
     */
    loadCourseBySlug({state, commit}, slugs) {
      return new Promise((resolve, reject) => {
        axios.get(process.env.SPENV.APP_URL + '/api/v2/software/' + slugs.software + '/' + slugs.course, {withCredentials: true}).then(
          (response) => {
            commit('setSoftware', {
              software: response.data,
              isCourseListLoaded: false,
            });
            resolve();
          },
          (error) => {
            console.log(error);
            reject(error);
          },
        );
      });
    },
    /**
     * Save bookmark for lesson jhamm 5/2/18
     * @param {object}
     * @returns Promise
     * @param lesson
     */
    saveBookmark({state, commit}, lesson) {
      return new Promise((resolve, reject) => {
        commit('saveBookmark', lesson);
        axios.post(process.env.SPENV.APP_URL + '/api/v1/app/bookmarks', {
          requestSrc: 'library',
          lessonid: lesson.id,
        }, {withCredentials: true}).then(
          (response) => {
            resolve();
          },
          (error) => {
            reject(error);
            commit('deleteBookmark', lesson);
            console.log(error);
          },
        );
      });
    },
    /**
     * Record lesson view to DB jhamm 5/9/18
     * @param {object}
     * @returns Promise
     * @param data
     */
    recordLessonView({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(process.env.SPENV.APP_URL + '/api/v1/app/views/record', data, {
          withCredentials: true,
          validateStatus: function(status) {
            return status < 500; // Reject only if the status code is greater than or equal to 500
          }
        }).then(
          (response) => {
            resolve(response.data);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    /**
     * Delete bookmark for lesson jhamm 5/2/18
     * @param {object}
     * @returns Promise
     * @param lesson
     */
    deleteBookmark({state, commit}, lesson) {
      return new Promise((resolve, reject) => {
        commit('deleteBookmark', lesson);
        axios.delete(process.env.SPENV.APP_URL + '/api/v1/app/bookmarks/library/' + lesson.id, {withCredentials: true}).then(
          (response) => {
            resolve();
          },
          (error) => {
            reject(error);
            commit('saveBookmark', lesson);
            console.log(error);
          },
        );
      });
    },
    setCertNotified({state, commit}, data) {
      // store the date_notified in the certs table when the member click on the certs tab (only when you are status=close and date_notifed is null)
      return new Promise((resolve, reject) => {
        axios.post(process.env.SPENV.APP_URL + '/api/v2/cert/set-notified', data, {
          withCredentials: true,
          headers: {'Content-Type': 'application/json'},
        }).then(
          (response) => {
            commit('setCertNotifiedDate', data);
            resolve();
          },
          (error) => {
            reject(error);
          },
        );
      });
    },
  },
  getters: {
    getAll(state) {
      return state.software_list.sort((a, b) => {
        return a.order - b.order;
      });
    },
    getCourseChanges (state) {
      return state.courseChanges;
    },
    getLockedModalShow(state) {
      return state.lockedModalShow;
    },
    getLearningPaths (state) {
      return state.learningPaths;
    },
    getSelectedLesson(state) {
      return state.selectedLesson;
    },
    getSoftwareListLoaded(state) {
      return state.loaded.software_list;
    },
    getSoftwareBySlug(state) {
      return function(slug) {
        if (state.software_list.length === 0) {
          return null;
        }
        for (let i = 0; i < state.software_list.length; i++) {
          if (state.software_list[i].slug === slug) {
            return state.software_list[i];
          }
        }
        return null;
      };
    },
    getMetaData(state) {
      return state.metaData;
    },
    getSoftwareName() {
      return function(slugs) {
        let software = this['software/getSoftwareBySlug'](slugs.software);
        if (!software) {
          return null;
        }
        return software.name;
      };
    },
    getSoftwareId() {
      return function(slugs) {
        let software = this['software/getSoftwareBySlug'](slugs.software);
        if (!software) {
          return null;
        }
        return software.id;
      };
    },
    getCourseBySlug(state) {
      return function(slugs) {
        let software = this['software/getSoftwareBySlug'](slugs.software);
        if (!software) {
          return null;
        }
        if (!software.courses || software.courses.length === 0) {
          return null;
        }
        for (let a = 0; a < software.courses.length; a++) {
          if (software.courses[a].slug === slugs.course) {
            return software.courses[a];
          }
        }
        return null;
      };
    },
    getIsNew (state) {
      return state.isNew;
    },
  },
};
export default software;
