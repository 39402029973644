import accounts from './accounts';
import admin from './admin';
import classes from './classes';
import paths from './paths';
import search from './search';
import skillsgap from './skillsgap';
import software from './software';

let defaultRoute = {
  path: '*',
  redirect: '/' + process.env.SPENV.LIBRARY_FOLDER_DISPLAY
};

// All new entry routes will need to a loadbalancer rule and cache whitelist on Sucuri
export default {
  all: [].concat(
    accounts,
    admin,
    classes,
    defaultRoute,
    paths,
    search,
    skillsgap,
    software
  )
};
